"use strict";

var scrollAnimate = require("base/components/scrollAnimate");
var formValidation = require("base/components/formValidation");
/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = "alert-success";
    } else {
        status = "alert-danger";
    }

    if ($(".email-signup-message").length === 0) {
        $("body").append(
            "<div class=\"email-signup-message\"></div>"
        );
    }
    $(".email-signup-message")
        .append("<div class=\"email-signup-alert text-center " + status + "\">" + data.msg + "</div>");

    setTimeout(function () {
        $(".email-signup-message").remove();
        button.removeAttr("disabled");
    }, 3000);
}

/**
 * Sets a modal cookie with specified name, value, and expiration
 */
function setModalCookie() {
    let cookieName = $(".slot-modal").data("cookie-name");
    let cookieValue = $(".slot-modal").data("cookie-value");
    let daysToExpire = $(".slot-modal").data("expiration");

    let today = new Date();
    let futureDate = new Date(today.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
    let expireDate = futureDate.toUTCString();

    document.cookie = cookieName + "=" + cookieValue + "; expires=" + expireDate + "; path=/";
}

module.exports = function () {
    $(".back-to-top").click(function () {
        scrollAnimate();
    });


    if ($(".slot-modal").length > 0) {
        let utm = $(".slot-modal").data("excluding-utms");
        let cookieExists = document.cookie.includes($(".slot-modal").data("cookie-name"));

        if (window.location.search.length > 0) {
            for (const param of utm) {
                if (window.location.search.includes(param)) {
                    setModalCookie();
                    return;
                }
            }
        }

        if (!cookieExists) {
            let timeToShow  = $(".slot-modal").data("time");
            let currentTime = timeToShow ? timeToShow : "2000";
            let pagesToShow = Object.values($(".slot-modal").data("pages"));
            let pageType = $("[data-page-type]").data("page-type");

            if (jQuery.inArray(pageType, pagesToShow) >= 0) {
                setTimeout(function () {
                    $("#emailSignupModal").modal("show");
                    $("#emailSignupModal").removeAttr("style");
                    setModalCookie();
                    window.openedSignupModal = 1;
                    window.dispatchEvent(new CustomEvent("triggerEmailSignupOverlayView", {}));
                }, currentTime);
            }
        }
    }

    $("#confirmation-modal-btn").on("click", function () {
        $("#confirmationModal").modal("hide");
        $(".btn-login").trigger("click");
        $(".login-form-nav").addClass("modal-sign-up");
        $(".js-form-email").val($("#emailSignupInput").val());
    });

    $(".subscribe-email").on("click", function (e) {
        e.preventDefault();
        var url = $(this).data("href");
        var button = $(this);
        var emailId = $("input[name=hpEmailSignUp]").val();
        $.spinner().start();
        $(this).attr("disabled", true);
        $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: {
                emailId: emailId
            },
            success: function (data) {
                displayMessage(data, button);
                if (data.success) {
                    window.location.href = data.redirectUrl;
                }
            },
            error: function (err) {
                displayMessage(err, button);
            }
        });
    });

    $(".js-modal-form-signup").on("submit", function  (e) {
        e.preventDefault();
        var isValid = $(this).isValid();
        var url = $(this).data("action");
        var button = $(this).find("js-modal-subscribe-email");
        var emailField = $(this).find("input[name=emailSignupInput]");
        var email = emailField.val();

        if (isValid) {
            button.attr("disabled", true);
            $.ajax({
                url: url,
                type: "post",
                dataType: "json",
                data: {
                    emailId: email
                },
                success: function (data) {
                    if (data.success) {
                        $("#emailSignupModal").modal("hide");
                        $("#confirmationModal").modal("show");
                    }
                    window.dispatchEvent(new CustomEvent("triggerEmailSignup", {}));
                },
                error: function (err) {
                    displayMessage(err, button);
                }
            });
        }
    });

    $.fn.isValid = function () {
        return this[0].checkValidity();
    };

    $(".js-form-signup").on("submit", function  (e) {
        e.preventDefault();
        var isValid = $(this).isValid();
        var url = $(this).data("action");
        var button = $(this).find("js-footer-subscribe-email");
        var form = $(this);
        var emailField = $(this).find("input[name=hpEmailSignUpFooter]");
        var email = emailField.val();

        if (isValid) {
            $.spinner().start();
            button.attr("disabled", true);
            $.ajax({
                url: url,
                type: "post",
                dataType: "json",
                data: {
                    emailId: email,
                    emailField: "hpEmailSignUpFooter"
                },
                success: function (data) {
                    $.spinner().stop();
                    if (data.success) {
                        $(".btn-login").trigger("click");
                        $(".signup-success").remove();
                        $(".js-form-email").val($("#inputEmail").val());
                        $("<div class='text text-sm medium signup-success pdd-8-bot'>" + data.msg + "</div>").insertBefore(".registered-form.registration");

                        window.dispatchEvent(new CustomEvent("triggerEmailSignup", {}));
                    } else {
                        formValidation(form, {fields: data.fields});
                    }
                },
                error: function (err) {
                    displayMessage(err, button);
                }
            });
        }
    });

    $("body").on("click", ".js-login-drawer-close, .js-login-tab", function () {
        $(".signup-success").remove();
    });

    $("input").on("keypress", function () {
        if ($(this).hasClass("is-invalid")) {
            $(this).removeClass("is-invalid");
        }
    });

    $("input[type='checkbox']").on("click", function () {
        if ($(this).hasClass("is-invalid")) {
            $(this).removeClass("is-invalid");
        }
    });
};
