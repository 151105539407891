"use strict";

let formHelpers = require("./formErrors");
let scrollAnimate = require("base/components/scrollAnimate");
let createErrorNotification = require("base/components/errorNotification");
let GUEST_FORM = "#guest-customer";
let REGISTERED_FORM = "#registered-customer";
let ERROR_SECTION = ".customer-error";

/**
 * @returns {boolean} If guest is active, registered is not visible
 */
function isGuestFormActive() {
    return $(GUEST_FORM).parent(".tab-pane").hasClass("active");
}

/**
 * Clear any previous errors in the customer form.
 */
function clearErrors() {
    $(ERROR_SECTION).children().remove();
    formHelpers.clearPreviousErrors(".customer-information-block");
}

/**
 * @param {Object} customerData - data includes checkout related customer information
 * @param {Object} orderData - data includes checkout related order information
 */
function updateCustomerInformation(customerData, orderData) {
    let $container = $(".js-customer-summary");
    let email = customerData.profile && customerData.profile.email ? customerData.profile.email : orderData.orderEmail;

    if (email) {
        $container.find(".js-customer-email").text(email);
        $container.removeClass("d-none");
    }

    if (customerData.registeredUser) {
        $container.find(".edit-button").hide();
    } else {
        $container.find(".edit-button").show();
    }
}


/**
 * Handle response from the server for valid or invalid form fields.
 * @param {Object} defer - the deferred object which will resolve on success or reject.
 * @param {Object} data - the response data with the invalid form fields or
 *  valid model data.
 */
function customerFormResponse(defer, data) {
    let parentForm = isGuestFormActive() ? GUEST_FORM : REGISTERED_FORM;
    let formSelector = ".customer-section " + parentForm;

    // highlight fields with errors
    if (data.error) {
        if (data.fieldErrors.length) {
            data.fieldErrors.forEach(function (error) {
                if (Object.keys(error).length) {
                    formHelpers.loadFormErrors(formSelector, error);
                }
            });
        }

        if (data.customerErrorMessage) {
            createErrorNotification(ERROR_SECTION, data.customerErrorMessage);
        }

        if (data.fieldErrors.length || data.customerErrorMessage || (data.serverErrors && data.serverErrors.length)) {
            defer.reject(data);
        }

        if (data.cartError) {
            window.location.href = data.redirectUrl;
            defer.reject();
        }
    } else {
        // Populate the Address Summary
        $("body").trigger("checkout:updateCheckoutView", {
            order: data.order,
            customer: data.customer,
            csrfToken: data.csrfToken
        });
        scrollAnimate($(".shipping-form"));
        defer.resolve(data);
    }
}

function initLoginTabEvents() {
    $(".js-toggle-login-tab").on("click", function (e) {
        e.preventDefault();
        $(".login-form-nav #login-tab").trigger("click");
    });
}

function initClearLoginErrors() {
    var inputFields = $("input[type='text']");
    inputFields.on("input", function () {
        $(".already-registered").addClass("d-none");
        $(".already-registered button").attr("disabled", true);
    });
}

module.exports = {
    methods: {
        clearErrors: clearErrors,
        updateCustomerInformation: updateCustomerInformation,
        customerFormResponse: customerFormResponse,
        isGuestFormActive: isGuestFormActive,
        initLoginTabEvents: initLoginTabEvents,
        initClearLoginErrors: initClearLoginErrors
    },

    vars: {
        GUEST_FORM: GUEST_FORM,
        REGISTERED_FORM: REGISTERED_FORM
    }

};
