"use strict";

var scrollAnimate = require("base/components/scrollAnimate");

/**
 * Display error messages and highlight form fields with errors.
 * @param {string} parentSelector - the form which contains the fields
 * @param {Object} fieldErrors - the fields with errors
 */
function loadFormErrors(parentSelector, fieldErrors) { // eslint-disable-line
    // Display error messages and highlight form fields with errors.
    $.each(fieldErrors, function (attr) {
        $("*[name=" + attr + "]")
            .addClass("is-invalid")
            .siblings(".invalid-feedback")
            .html(fieldErrors[attr])
            .parent().addClass("invalid-input");
    });
    // Animate to top of form that has errors
    scrollAnimate($(parentSelector));
}

/**
 * Clear the form errors.
 * @param {string} parentSelector - the parent form selector.
 */
function clearPreviousErrors(parentSelector) {
    $(parentSelector).find(".form-control.is-invalid").removeClass("is-invalid");
    $(".error-message").hide();
}

module.exports = {
    loadFormErrors: loadFormErrors,
    clearPreviousErrors: clearPreviousErrors
};
