window.jQuery = window.$ = require("jquery");
var processInclude = require("base/util");

$(document).ready(function () {
    processInclude(require("./components/menu"));
    processInclude(require("./components/subMenu"));
    processInclude(require("base/components/cookie"));
    processInclude(require("./components/consentTracking"));
    processInclude(require("./components/miniCart"));
    processInclude(require("./components/footer"));
    processInclude(require("./components/clientSideValidation"));
    processInclude(require("./components/collapsibleItem"));
    processInclude(require("./components/search"));
    processInclude(require("./components/toolTip"));
    processInclude(require("./components/carouselItems"));
    processInclude(require("./header"));
    processInclude(require("./login"));
    processInclude(require("./components/spinner"));
    processInclude(require("./productTile"));
    processInclude(require("./components/storesModal"));
    processInclude(require("./components/drawer"));
    processInclude(require("./components/socialIcons"));
    processInclude(require("plcc/setup/plccInit"));
    $("html").addClass("loaded");
});

require("base/thirdParty/bootstrap");
require("./components/spinner");
require("@accessible360/accessible-slick");
