"use strict";
let drawer = require("storefront/components/drawer");
var clientSideValidation = require("storefront/components/clientSideValidation");
var validateForm = require("base/components/formValidation");

/**
* Updates the wishlist icons on the product tiles based on the customer's wishlist data.
* 
* This function retrieves the wishlist data from a DOM element with the class 'a-customer-data'.
* It then iterates over the product IDs in the wishlist and updates the corresponding product tiles
* to reflect that they are in the wishlist. Specifically, it changes the classes and the heart icon
* to indicate that the product is in the wishlist.
* 
* @returns {void}
*/
function updateWishlistIcon() {
    var wishlist = $(".a-customer-data").data("wishlist");

    if (wishlist && wishlist.length) {
        var wishlistIds = wishlist.split(",");

        for (var i in wishlistIds) {
            var pid = wishlistIds[i].trim();
            if (pid != "") {
                var tile = $(".js-wish-list[data-pid=" + pid + "]");
                tile.removeClass("js-wishlist-tile-add");
                tile.addClass("js-wishlist-tile-remove");

                var iconHeart = tile.find(".icon-heart");
                if (iconHeart.length) {
                    iconHeart.find("use").attr("href", "#icon-full-heart");
                }
            }
        }
    }
}

/**
 * appends params to a url
 * @param {string} data - data returned from the server"s ajax call
 * @param {Object} icon - icon that was clicked to add a product to the wishlist
 */
function displayMessageAndChangeIcon(data, icon, pid) {
    $.spinner().stop();
    let wishlistTrigger = $(".js-wish-list[data-pid=" + pid + "]");
    wishlistTrigger = wishlistTrigger.length > 0 ? wishlistTrigger : $(".product-detail .js-wish-list");
    wishlistTrigger.toggleClass("js-wishlist-tile-remove")
        .toggleClass("js-wishlist-tile-add")
        .find("svg use").attr("href", icon);
    wishlistTrigger.find(".js-wishlist-cta").toggleClass("d-none");

    if (!data.isWishlistRemoved) {
        $(".wishlist-messages")
            .html("<span class='add-to-wishlist-alert'><svg class='icon icon-md margin-8-right'><use href='#ui-icon-check'></use></svg> " +data.msg + "</div>")
            .show();

        $(".wishlist-messages").delay(5000).fadeOut("slow");
    }
}

function openWishListAddDrawer(pid, optionId = null, optionVal = null, fromCart = null) {
    var target = ".js-add-wishlist-item-drawer";
    var url = $(target).data("add-url");

    if (!url || !pid) {
        return;
    }

    var form = {
        pid: pid,
        optionId: optionId,
        optionVal: optionVal,
        fromCart: fromCart,
    };

    var $isDigitalGiftCard = $("#isDigitalGiftCard");
    var $eGiftForm  = $(".js-egift-form");
    form.isDigitalGiftCard = false;
    if ($isDigitalGiftCard.length > 0 && $isDigitalGiftCard.val() === "true") {
        clientSideValidation.functions.clearForm(".js-egift-form");
        $eGiftForm.serializeArray().forEach(function (field) {
            form[field.name] = field.value;
        });
        form.isDigitalGiftCard = true;
    }

    $.spinner().start();
    $.ajax({
        url: url,
        type: "POST",
        dataType: "json",
        data: form,
        success: function (data) {
            if (data && !data.success && data.fields) {
                validateForm($eGiftForm, data);
                $.spinner().stop();
                return;
            }
            $(target).html(data.modalHTML);
            drawer.openDrawer(target);
            $.spinner().stop();
        },
        error: function (err) {
            console.log(err);
        }
    });
    return false;
}

module.exports = {
    addToWishlist: function () {
        var icon = "#icon-full-heart";

        $(document).on("submit", ".js-form-add-wishlist-item", function (e) {
            e.preventDefault();
            $(".js-at-least-one-list").hide();

            var form = $(this);
            var url = form.attr("action");
            var lists = form.find(".wishlist-checkbox");
            var listIds = "";
            var pid = form.find("input[name='pid']").val();

            lists.each(function () {
                var list = $(this);
                var isChecked = list.is(":checked");
                if (isChecked) {
                    listIds = listIds !== "" ? listIds + "|" + list.val() : list.val();
                }
            });

            if (listIds === "") {
                $(".js-at-least-one-list").show();
                return;
            }

            form.append("<input type='hidden' name='listIds' value='" + listIds + "'>");
            $(".js-add-wishlist-item-drawer .drawer-container").spinner().start();
            $.ajax({
                url: url,
                type: "POST",
                dataType: "json",
                data: form.serialize(),
                success: function (data) {
                    displayMessageAndChangeIcon(data, icon, pid);
                    drawer.closeDrawer(".js-add-wishlist-item-drawer");

                    if ($(".cart-page").length > 0) {
                        $(".remove-product[data-pid='" + pid + "']").trigger("click");
                    }

                    window.dispatchEvent(new CustomEvent("addToFavoriteSuccess", {detail: {data: data.aFavorite}}));

                    $(".a-customer-data").data("wishlist", data.wishlistIdsStr);

                    $.spinner().stop();
                },
                error: function (err) {
                    console.log(err);
                    window.dispatchEvent(new CustomEvent("addToFavoriteFail", {data: err.msg}));
                }
            });
        });
    },
    openAddDrawer: function () {
        $(document).on("wishlistAfterLogin", function (e, pid) {
            openWishListAddDrawer(pid);
        });

        $(document).on("click", ".js-wishlist-tile-add", function (e) {
            e.preventDefault();

            let pid = $(this).data("pid");
            let fromCart = $(this).data("from-cart");
            let optionId = $(this).closest(".product-detail").find(".product-option").attr("data-option-id") || null;
            let optionVal = $(this).closest(".product-detail").find(".options-select option:selected").attr("data-value-id") || null;

            openWishListAddDrawer(pid, optionId, optionVal, fromCart);
        });
    },
    removeFromWishlist: function () {
        $("body").on("click", ".js-wishlist-tile-remove", function (e) {
            e.preventDefault();
            var target = ".js-add-wishlist-item-drawer";
            var url = $(target).data("remove-url");
            var icon = "#icon-empty-heart";
            var pid = $(this).data("pid");

            if (!url || !pid) {
                return;
            }

            $.spinner().start();
            $.ajax({
                url: url,
                type: "GET",
                dataType: "json",
                data: {
                    pid: pid
                },
                success: function (data) {
                    displayMessageAndChangeIcon(data, icon, pid);
                    $(".a-customer-data").data("wishlist", data.wishlistIdsStr);
                },
                error: function (err) {
                    displayMessageAndChangeIcon(err, icon, pid);
                }
            });
        });
    },
    showWishlistDrawer: function () {
        const url =new URL(location.href);
        for (const [key, value] of url.searchParams.entries()) {
            if (key === "showWishListsModal") {
                $(document).trigger("wishlistAfterLogin", [value]);
            }
        }
    },
    createWishlist: function () {
        // CREATE wishlist
        $(document).on("click", ".js-create-wishlist", function () {
            // show error if user has 5 wishlists already
            if ($(".wishlist-lists-container").children(".wishlist-card").length == 5) {
                $(this).parent().addClass("flex-wrap").find(".js-maxed-wishlists-no").removeClass("d-none");
                return false;
            }

            let pid = $(this).data("pid");
            let target = $(this).data("target");

            $(target).find(".js-input-pid").val(pid);
            drawer.openDrawer(target);
        });

        $(document).on("submit", ".js-create-favorites-list", function (e) {
            e.preventDefault();
            let icon = "#icon-full-heart";
            let isValid = $(this).isValid();
            let url = $(this).attr("action");
            let listName = $(".js-favorites-list-name").val();
            let addWishlistForm = $(".js-form-add-wishlist-item").serialize();
            let pid = $(".js-input-pid").val();
            let formdData = addWishlistForm + "&" + "listName=" + listName;

            if (isValid) {
                $(".js-create-wishlist-drawer .drawer-container").spinner().start();
                $.ajax({
                    url: url,
                    type: "post",
                    data: formdData,
                    success: function (data) {
                        $.spinner().stop();
                        if (!data.error) {
                            $(".js-drawer-close").click();
                            if (pid) {
                                displayMessageAndChangeIcon(data, icon, pid);
                            } else {
                                window.location.href = data.redirectUrl;
                            }
                        }

                        if (data.error && data.errorMsg) {
                            displayMessageAndChangeIcon(data, icon, pid);
                            $(".invalid-feedback").text(data.errorMsg).addClass("d-block");
                        }
                    }
                });
            }
        });
    },
    isInWishlist: function () {
        updateWishlistIcon();

        $(document).on("product:updateRecsWishlist", function () {
            updateWishlistIcon();
        });
    }
};
