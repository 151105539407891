"use strict";

module.exports = function () {
    $(document).on("mouseenter focusin", ".info-icon:not(.pdp-info-icon)", function () {
        $(this).find(".tooltip").removeClass("d-none");
    });

    $(document).on("click", ".pdp-info-icon", function () {
        $(this).find(".tooltip").removeClass("d-none");
    });

    $(document).on("mouseleave focusout", ".info-icon", function () {
        $(this).find(".tooltip").addClass("d-none");
    });
};
