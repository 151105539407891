"use strict";
var keyboardAccessibility = require("base/components/keyboardAccessibility");
var debounce = require("lodash/debounce");
var stickyHeaderSettings = $(".header-nav").data("sticky-settings");
var isStickyHeaderEnabled = stickyHeaderSettings !== "sticky-disabled" ? true : false;
var stickyHeaderMobile = isStickyHeaderEnabled && (stickyHeaderSettings === "sticky-mobileonly" || stickyHeaderSettings === "sticky-both") ? true : false;
var stickyHeaderDesktop = isStickyHeaderEnabled && (stickyHeaderSettings === "sticky-desktoponly" || stickyHeaderSettings === "sticky-both") ? true : false;

let clearSelection = function (element) {
    $(element).closest(".dropdown.show").children(".nav-link").attr("aria-expanded", "false");
    $(element).closest(".dropdown.show").children(".dropdown-menu").attr("aria-hidden", "true");
    $(element).closest(".dropdown.show").removeClass("show");
    $("div.menu-group > ul.nav.navbar-nav > li.nav-item > a").attr("aria-hidden", "false");
    $(element).closest("li").detach();
};


/**
 * Loads the Level 1 carousel for categories
 * @returns {void}
 */
function loadL1Carousel() {
    let $l1Carousel = $(".js-first-navbar");
    let isCarouselInitialized = $l1Carousel.hasClass("slick-initialized");

    // slick for L1 categories
    if ($(window).outerWidth() >= 1024) {
        let totalWidth = 0;
        let numberOfItems = 0;
        let marginBetweenItems = 48;
        let containerWidth = $(".menu-wrapper").width();

        let arrowNextCategoryAria= $(".menu-group").attr("data-aria-next-msg");
        let arrowPrevCategoryAria= $(".menu-group").attr("data-aria-prev-msg");

        if  ($(".menu-toggleable-left").hasClass("in")) {
            $(".navbar>.close-menu>.close-button").click();
        }

        $l1Carousel.find(".nav-item.d-lge-block").each(function () {
            totalWidth += parseInt($(this).width(), 10) + marginBetweenItems;
            if (totalWidth < containerWidth) {
                numberOfItems = numberOfItems + 1;
            }
        });

        if (totalWidth > containerWidth && !isCarouselInitialized) {
            $l1Carousel.slick({
                appendArrows: $(".main-menu .menu-group"),
                infinite: false,
                speed: 300,
                draggable: false,
                slidesToShow: numberOfItems,
                slidesToScroll: 2,
                variableWidth: true,
                prevArrow:"<button class='slick-arrow slick-prev' aria-label='" + arrowPrevCategoryAria + "'><svg class='icon-md icon-color-t300'><use href='#chevron-left'></use></svg></button>",
                nextArrow:"<button class='slick-arrow slick-next' aria-label='" + arrowNextCategoryAria + "'><svg class='icon-md icon-color-t300'><use href='#chevron-right'></use></svg></button>"
            });
        }
    } else if (isCarouselInitialized) {
        $l1Carousel.slick("unslick");
    }
}

/**
 * Loads the banner carousel with specified settings
 */
function loadBannerCarousel() {
    let autoplaySpeed = $(".banner-content .slick-carousel").data("autoplay-speed");
    let $slick_slider = $(".banner-content .slick-carousel");
    let arrowNextBannerAria= $(".header-banner").attr("data-aria-next-msg");
    let arrowPrevBannerAria= $(".header-banner").attr("data-aria-prev-msg");
    let $bannerlink = $(".banner-content .rewards-link");
    let settings = {
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: autoplaySpeed,
        dots: false,
        arrows: true,
        mobileFirst: true,
        prevArrow:"<button class='slick-arrow slick-prev' style='left:0;' aria-label='"+arrowPrevBannerAria+"'><svg class='icon-sm icon-color-t300'><use href='#chevron-left'></use></svg></button>",
        nextArrow:"<button class='slick-arrow slick-next' style='right:0;' aria-label='"+arrowNextBannerAria+"'><svg class='icon-sm icon-color-t300'><use href='#chevron-right'></use></svg></button>",
        pauseIcon: "<span class='slick-pause-icon' aria-hidden='true'><svg class='icon-sm icon-pause d-none'><use href='#icon-pause'></use></svg></span>",
        playIcon: "<span class='slick-play-icon' aria-hidden='true'><svg class='icon-sm icon-play d-none'><use href='#icon-play'></use></svg></span>"
    };

    if ($(window).outerWidth() <= 1023 && $(".mobile-slick .slide").length > 1 && !$slick_slider.hasClass("slick-initialized")) {
        $slick_slider.slick(settings);
        $bannerlink.addClass("margin-fixed");
    } else if ($(window).outerWidth() >= 1024) {
        if ($slick_slider.hasClass("slick-initialized")) {
            $bannerlink.removeClass("margin-fixed");
            $slick_slider.slick("unslick");
        }
    }
}

/**
 * Loads and configures the promotion carousel
 */
function loadPromotionCarousel() {
    let autoplaySpeed = $(".mobile-promo-content").data("autoplay-speed");
    let $slick_slider = $(".mobile-promo-content");

    if ($(".promo-content").length > 0 && $(".mobile-promo-content").length > 0 && $(window).outerWidth() <= 1023 && !$slick_slider.hasClass("slick-initialized")) {
        let settings = {
            slidesToShow: 1,
            autoplay: true,
            autoplaySpeed: autoplaySpeed,
            dots: false,
            arrows: true,
            mobileFirst: true,
            prevArrow:"<button class='slick-arrow slick-prev'><svg class='icon-sm icon-color-t300'><use href='#chevron-left'></use></svg></button>",
            nextArrow:"<button class='slick-arrow slick-next'><svg class='icon-sm icon-color-t300'><use href='#chevron-right'></use></svg></button>",
        };
        $slick_slider.slick(settings);
    } else if ($(window).outerWidth() >= 1024) {
        if ($slick_slider.hasClass("slick-initialized")) {
            $slick_slider.slick("unslick");
        }
    }
}

/**
 * Toggles the visibility of a sticky header and adjusts mobile slick slider position.
 *
 * This function removes the "sticky-nav" class from the specified header element,
 * effectively hiding the sticky header. Additionally, if the user is on a mobile
 * device and there is a slick slider with the class "mobile-slick" present on the page,
 * the function will adjust the slider's position to ensure proper rendering.
 *
 * @param {jQuery} header - The jQuery object representing the header element to be modified.
 * @param {boolean} isMobile - A boolean flag indicating whether the current device is a mobile device.
 *                             If true, the function will also adjust the position of the mobile slick slider.
 */
function showHideStickyHeader(header, isMobile) {
    header.removeClass("sticky-nav");
    if (isMobile && $(".mobile-slick").length > 0) {
        $(".mobile-slick").slick("setPosition");
    }
}

module.exports = function () {
    var isDesktop = function (element) {
        return $(element).parents(".menu-toggleable-left").css("position") !== "fixed";
    };

    let headerBannerStatus = window.sessionStorage.getItem("hide_header_banner");

    $(".announcement-banner .close").on("click", function () {
        $(".announcement-banner").addClass("d-none");
        window.sessionStorage.setItem("hide_header_banner", "1");
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $(".announcement-banner").removeClass("d-none");
    }

    keyboardAccessibility(".main-menu .nav-link, .main-menu .dropdown-link",
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass("nav-item")) { // top level
                    $(".navbar-nav .show").removeClass("show")
                        .children(".dropdown-menu")
                        .removeClass("show");
                    menuItem.addClass("show").children(".dropdown-menu").addClass("show");
                    menuItem.find("ul > li > a")
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass("show").children(".dropdown-menu").removeClass("show");
                    if ((menuItem.next().length <= 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find("li > a") // set focus to the first menuitem
                            .first()
                            .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass("nav-item")) { // top level
                    menuItem.removeClass("show").children(".dropdown-menu").removeClass("show");
                    $(this).attr("aria-expanded", "false");
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass("dropdown")) {
                    menuItem.addClass("show").children(".dropdown-menu").addClass("show");
                    $(this).attr("aria-expanded", "true");
                    menuItem.find("ul > li > a")
                        .first()
                        .focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass("nav-item")) { // top level
                    menuItem.removeClass("show").children(".dropdown-menu").removeClass("show");
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass("show")
                        .children(".nav-link")
                        .attr("aria-expanded", "false");
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass("nav-item")) { // top level
                    menuItem.removeClass("show").children(".dropdown-menu").removeClass("show");
                    $(this).attr("aria-expanded", "false");
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest(".show").removeClass("show")
                        .closest("li.show").removeClass("show")
                        .children()
                        .first()
                        .focus()
                        .attr("aria-expanded", "false");
                }
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass("show")
                    ? menuItem
                    : menuItem.closest("li.show");
                parentMenu.children(".show").removeClass("show");
                parentMenu.removeClass("show").children(".nav-link")
                    .attr("aria-expanded", "false");
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    let header = $("header");
    let lastScrollPos = Number($(window).scrollTop());

    $(window).on("scroll", function (e) {
        let scrollPosition = Number($(window).scrollTop());
        let windowHeight = Number($(window).height());
        let bodyHeight = Number($("body").height().toFixed());
        let relativePosition = Number(Math.abs(bodyHeight - scrollPosition).toFixed());
        let minDiff = 83.125;
        let diff = Number(Math.abs(relativePosition - windowHeight).toFixed());
        let scrolledEnough = diff >= minDiff ? true : false;
        let isScrollingDown = (relativePosition !== windowHeight) && (relativePosition >= windowHeight) && (lastScrollPos < scrollPosition);
        let windowWidth = Number($(window).outerWidth());
        let isMobile = windowWidth < 992;
        let suggestions = $(".suggestions");
        let locationModal = $(".location-modal-centered").hasClass("show");

        if (isMobile && $(".prevent-scroll").length !== 0) {
            return;
        }

        if (!scrolledEnough && isScrollingDown) {
            return;
        }

        if (suggestions.is(":visible") && !isMobile) {
            suggestions.css("display", "none");
            $(".modal-background").hide();
        }

        if ((!suggestions.is(":visible") && isMobile && stickyHeaderMobile) || !isMobile && stickyHeaderDesktop) {
            if (isScrollingDown && scrolledEnough && scrollPosition > 40) {
                header.removeClass("sticky-nav");
                if (!locationModal) {
                    $(".js-search-field").blur();
                }
            } else {
                if (scrollPosition <= 40 && $(".prevent-scroll").length === 0) {
                    showHideStickyHeader(header, isMobile);
                    lastScrollPos = scrollPosition;
                } else if (scrollPosition > 40) {
                    header.addClass("sticky-nav");
                }
            }
        }

        lastScrollPos = $(window).scrollTop();
    });

    $(document).on("click", ".dropdown:not(.disabled) [data-toggle='dropdown']", function (e) {
        if (!isDesktop(this)) {
            e.preventDefault();
            $(".modal-background").show();
            // copy parent element into current UL
            let li = $("<li class=\"dropdown-item top-category\" role=\"button\"></li>");
            let link = $(this).clone().removeClass("dropdown-toggle")
                .removeAttr("data-toggle")
                .removeAttr("aria-expanded");
            li.append(link);
            let closeMenu = $("<li class=\"nav-menu\"></li>");
            closeMenu.append($(".close-menu").first().clone());
            $(this).parent().children(".dropdown-menu")
                .prepend(li)
                .prepend(closeMenu)
                .attr("aria-hidden", "false");
            // copy navigation menu into view
            $(this).parent().addClass("show");
            $(this).attr("aria-expanded", "true");
            $(link).focus();
            $("div.menu-group > ul.nav.navbar-nav > li.nav-item > a").attr("aria-hidden", "true");

            $(".top-category").detach();

            let navigationCategory = $(".nav-menu .close-menu").children(".back").children(".js-back-button").children(".js-selected_category");
            let clickedCategory = $(this).children(".js-category-name").text().trim();

            if (navigationCategory.length > 1) {
                let navigationCategoryLength = navigationCategory.length - 1;
                $(this).data("previous-category", $(navigationCategory[0]).text());
                $(navigationCategory[navigationCategoryLength]).text(clickedCategory);
                $(navigationCategory[navigationCategoryLength]).data("previous-category", $(navigationCategory[0]).text());
            } else {
                navigationCategory.text(clickedCategory);
            }
        }
    });

    $(".menu-group .nav-link").on("mouseenter", function () {
        if (isDesktop(this)) {
            // need to close all the dropdowns that are not direct parent of current dropdown
            $(".menu-group .nav-item").removeClass("show").attr("aria-expanded", "false");
            $(".dropdown-menu").removeClass("show");
            let categoryID = $(this).attr("id");
            $(".mega-menu-wrapper").find("[aria-label=" + categoryID + "]").addClass("show");
            $(this).parent().addClass("show");
            $(this).attr("aria-expanded", "true");
        }
    });

    function closeMegaMenu() {
        if (isDesktop(this)) {
            $(".dropdown-menu").removeClass("show");
            $(".dropdown").removeClass("show");
            $(".nav-item").removeClass("show");
            $(".dropdown-menu").children(".nav-link").attr("aria-expanded", "false");
        }
    }

    $(".dropdown-menu").on("mouseleave", closeMegaMenu);
    $(".header, .announcement-banner, #maincontent").on("mouseenter", closeMegaMenu);

    $(".navbar>.close-menu>.close-button").on("click", function (e) {
        e.preventDefault();
        $("html").removeClass("no-overflow");
        $(".menu-toggleable-left").removeClass("in");
        $("body").append($(".modal-background").hide());
        clearSelection($(".dropdown-menu:not([hidden])").find(".back"));

        $(".js-navbar-toggler").focus();

        $(".main-menu").attr("aria-hidden", "true");
        $(".main-menu").siblings().attr("aria-hidden", "false");
        $("header").siblings().attr("aria-hidden", "false");
    });

    $(".navbar-nav").on("click", ".back", function (e) {
        e.preventDefault();
        clearSelection($(this));
    });

    $(".js-navbar-toggler").click(function (e) {
        e.preventDefault();
        $("html").toggleClass("no-overflow");
        $(".main-menu").toggleClass("in");
        $(".header-nav").append($(".modal-background"));
        $(".modal-background").show();

        $(".main-menu").removeClass("d-none");
        $(".main-menu").attr("aria-hidden", "false");
        $(".main-menu").siblings().attr("aria-hidden", "true");
        $("header").siblings().attr("aria-hidden", "true");

        $(".main-menu .nav.navbar-nav .nav-link").first().focus();
    });

    keyboardAccessibility(".navbar-header .user",
        {
            40: function ($popover) { // down
                if ($popover.children("a").first().is(":focus")) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children("a").first().focus();
                }
            },
            38: function ($popover) { // up
                if ($popover.children("a").first().is(":focus")) {
                    $(this).focus();
                    $popover.removeClass("show");
                } else {
                    $popover.children("a").first().focus();
                }
            },
            27: function () { // escape
                $(".navbar-header .user .popover").removeClass("show");
                $(".user").attr("aria-expanded", "false");
            },
            9: function () { // tab
                $(".navbar-header .user .popover").removeClass("show");
                $(".user").attr("aria-expanded", "false");
            }
        },
        function () {
            var $popover = $(".user .popover li.nav-item");
            return $popover;
        }
    );

    $("body").on("click", ".js-navbar>.nav-item>.popover-open", function (e) {
        e.preventDefault();

        if ($(this).siblings(".popover").hasClass("d-none")) {
            $(".popover").addClass("d-none");
            $(".navbar-popover").removeClass("active");
            $(".js-navbar>.nav-item").attr("aria-expanded", "false");
            $(this).siblings(".popover").removeClass("d-none");
            $(this).parent(".nav-item").attr("aria-expanded", "true");
            $(this).parent(".nav-item").parent(".navbar-popover").addClass("active");
            $(this).attr("aria-expanded", "true");
            $("html").addClass("prevent-scroll");
            $(".page").addClass("prevent-scroll");
        } else {
            $(".popover").addClass("d-none");
            $(".navbar-popover").removeClass("active");
            $(".js-navbar>.nav-item").attr("aria-expanded", "false");
            $("html").removeClass("prevent-scroll");
            $(".page").removeClass("prevent-scroll");
        }
    });

    $("body").click(function (e) {
        let popover = $(".js-navbar>.nav-item");
        let popoverClose = $(".popover-header-top>.close");

        if (!popover.is(e.target) && !popover.has(e.target).length || popoverClose.has(e.target).length > 0) {
            $(".popover").addClass("d-none");
            popover.attr("aria-expanded", "false");
            $(".js-navbar>.nav-item>.popover-open").attr("aria-expanded", "false");
            $(".navbar-popover").removeClass("active");
            $("html").removeClass("prevent-scroll");
            $(".page").removeClass("prevent-scroll");
        }
    });

    $(".popover-header-top>.close").click(function (e) {
        e.preventDefault();
        $(this).closest(".popover").addClass("d-none");
        $(this).closest(".nav-item").attr("aria-expanded", "false");
        $(".navbar-popover").removeClass("active");
        $("html").removeClass("prevent-scroll");
        $(".page").removeClass("prevent-scroll");
    });

    $(".banner-content>.row>.close-button").click(function (e) {
        e.preventDefault();
        $(this).closest(".announcement-banner").addClass("d-none");
    });

    //close menu when click outside
    $(".modal-background").on("click", function () {
        $(".menu-toggleable-left").removeClass("in");
        clearSelection($(".dropdown-menu:not([hidden])").find(".back"));
        $(this).hide();
        $("html").removeClass("no-overflow");
        $("html").removeClass("prevent-scroll");
        $(".page").removeClass("prevent-scroll");
    });

    $(".js-modal-lock-scroll").on("show.bs.modal", function () {
        $("html").css("overflow", "hidden");
        $("header").addClass("position-static");
    });

    $(".js-modal-lock-scroll").on("hidden.bs.modal", function () {
        $("html").removeAttr("style");
        $("header").removeClass("position-static");
    });

    $(document).ready(function () {
        loadL1Carousel();
        loadPromotionCarousel();
        loadBannerCarousel();
    });

    $(window).on("resize", debounce(function () {
        loadL1Carousel();
        loadPromotionCarousel();
        loadBannerCarousel();
    }, 500));
};
