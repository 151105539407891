"use strict";

var debounce = require("lodash/debounce");

/**
 * Sets the transition state of a collapsible element
 * @param {jQuery} $collapsibleParent - the parent element of the collapsible
 * @param {boolean} resetTransition - whether to reset the transition state
 * @returns {void}
 */
function setTransitionState($collapsibleParent, resetTransition) {
    let isMobileView = $(window).outerWidth() < 992;
    if ($collapsibleParent.hasClass("active") && !$collapsibleParent.hasClass("active-desktop") && !isMobileView) {
        if (resetTransition) {
            $collapsibleParent.removeClass("collapsible-transition");
        }

        var outerHeight = $collapsibleParent.find(".collapsible-header").outerHeight() + $collapsibleParent.find(".collapsible-body").outerHeight();

        $collapsibleParent.attr("style", "min-height:" + outerHeight + "px");

        if (resetTransition) {
            $collapsibleParent.addClass("collapsible-transition").removeClass("default-active");
        }
    } else {
        $collapsibleParent.removeAttr("style");
    }
}

module.exports = {
    init: function () {
        var sizes = ["xs", "sm", "md", "lg", "xl", "xxl"];

        sizes.forEach(function (size) {
            var selector = ".collapsible-" + size + " .title";

            var $collapsibleItems = $(selector).parents(".collapsible-" + size);

            $collapsibleItems.each(function () {
                if ($(this).hasClass("collapsible-transition")) {
                    setTransitionState($(this), true);
                }
            });

            $(window).on("resize orientationchange", debounce(function () {
                $collapsibleItems.each(function () {
                    if ($(this).hasClass("collapsible-transition")) {
                        setTransitionState($(this), false);
                    }
                });
            }, 500));

            $("body").on("click", selector, function (e) {
                e.preventDefault();

                var $collapsibleParent = $(this).parents(".collapsible-" + size);

                if ($collapsibleParent.hasClass("initialized")) {
                    return;
                }

                $collapsibleParent.toggleClass("active");

                if ($collapsibleParent.hasClass("collapsible-transition")) {
                    setTransitionState($collapsibleParent, false);
                }

                if ($collapsibleParent.hasClass("active")) {
                    $(this).attr("aria-expanded", true);
                } else {
                    $(this).attr("aria-expanded", false);
                }
            });
        });
    },
    methods: {
        setTransitionState: function ($collapsibleParent, resetTransition) {
            setTransitionState($collapsibleParent, resetTransition);
        }
    }
};
