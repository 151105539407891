"use strict";

function openDrawer(target) {
    if (target) {
        $(".popover").addClass("d-none");
        $(".drawer").removeClass("active");
        $(".drawer-wrapper").hide();
        $(target).children(".drawer-wrapper").show();

        setTimeout(() => {
            $(target).addClass("active");
            $("html, body").addClass("no-scroll-top");
        }, 10);
    }
}

function closeDrawer(target) {
    if (target) {
        $(".popover").addClass("d-none");
        $(target).removeClass("active");

        if ($(target).find(".login-form-nav").hasClass("modal-sign-up")) {
            $(".login-form-nav").removeClass("modal-sign-up");
        }

        setTimeout(() => {
            $(target).children(".drawer-wrapper").hide();
            $("html, body").removeClass("no-scroll-top");
        }, 10);
    }
}

$("body").on("click", ".js-drawer-open", function (e) {
    e.preventDefault();
    let target = $(this).data("target");

    openDrawer(target);
});

$("body").on("click", ".js-drawer-close", function (e) {
    e.preventDefault();
    let target = $(this).closest(".drawer");

    closeDrawer(target);
});

function createAddressVerificationDrawer(data) {
    const $submittedAddress = $("#original-address");
    $submittedAddress.data({
        addressLine: data.submittedAddress.address1,
        addressOne: data.submittedAddress.address1,
        addressTwo: data.submittedAddress.address2,
        city: data.submittedAddress.city,
        state: data.submittedAddress.stateCode,
        postalCode: data.submittedAddress.postalCode
    });
    let addressDrawer = $(".js-address-drawer");
    let suggestedAddressContent= $(".suggested-addresses");
    let originalAddressLine1 = addressDrawer.find(".original-address-line");
    let originalAddressLine2 = addressDrawer.find(".original-address-line2");
    let originalAddressCity = addressDrawer.find(".original-address-city");
    let originalAddressState = addressDrawer.find(".original-address-state");
    let originalAddressZip = addressDrawer.find(".original-address-zip");

    originalAddressLine1.empty().append(data.submittedAddress.address1);
    originalAddressLine2.empty();
    if (data.submittedAddress.address2) {
        originalAddressLine2.empty().append(data.submittedAddress.address2);
    }
    originalAddressCity.empty().append(data.submittedAddress.city);
    originalAddressState.empty().append(data.submittedAddress.stateCode + ", ");
    originalAddressZip.empty().append(data.submittedAddress.postalCode);

    suggestedAddressContent.empty();
    $(".original-address-wrapper").removeClass("selected");
    $(".original-address").removeAttr("selected");

    $(data.suggestedAddresses).each(function (index, suggestion) {
        suggestedAddressContent.append(
            `<input
                class="drawer-address-input pseudo-radio-inner js-drawer-radio suggested-address-${index}"
                type="radio"
                id="suggested-address-${index}"
                name="drawer-address"
                value="suggested-address-${index}"
                data-address-line="${suggestion.address1}"
                data-address-one="${suggestion.address1}"
                data-address-two="${suggestion.address2}"
                data-city="${suggestion.city}"
                data-state="${suggestion.state}"
                data-postal-code="${suggestion.postalCode}"
                />
            <label class="drawer-address-label form-check-label" for="suggested-address-${index}">
                <div class="d-flex flex-column justify-content-center w-100 text text-base medium text-color-t200 pdd-16-left">
                    <span class="suggested-address-line">
                        ${suggestion.address1}
                    </span>
                    <span class="suggested-address-two">
                        ${suggestion.address2 ? suggestion.address2 : ""}
                    </span>
                    <span class="suggested-address-city">
                        ${suggestion.city}
                    </span>
                    <div class="text text-base medium text-color-t200">
                        <span class="suggested-address-state">
                            ${suggestion.state + ", "}
                        </span>
                        <span class="suggested-address-zip">
                            ${suggestion.postalCode}
                        </span>
                    </div>
                </div>
            </label>`
        );
    });
}

module.exports = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer,
    methods: {
        createAddressVerificationDrawer: createAddressVerificationDrawer
    }
};
