function showAlert(response) {
    let fieldErrors = response.serverErrors || response.fieldErrors;

    Object.keys(fieldErrors).forEach(function (key) {
        $("body .customer-error").append(
            "<div class='alert-messages'></div>"
        );

        $(".alert-messages").empty().append(
            "<div class='alert alert-danger log-message-alert text-center margin-16-bot'"
            + " role='alert'>"
            + fieldErrors[key]
            + "</div>"
        );

        setTimeout(function () {
            $(".alert-messages").remove();
        }, 5000);
    });
}

module.exports = showAlert;
