"use strict";

/**
 * Show a spinner inside a given element
 * @param {element} $target - Element to block by the veil and spinner.
 *                            Pass body to block the whole page.
 */

module.exports = function () {
    document.addEventListener("spinner.start", function (event) {
        let $this = $(event.detail.selector);
        addSpinner($this);
    });

    document.addEventListener("spinner.stop", function (event) {
        let $this = $(event.detail.selector).find(".veil");
        removeSpinner($this);
    });

    function addSpinner($target, btnInput) {
        var $veil = $("<div class=\"veil\"></div>");
        $veil.append("<div class=\"spinner\"><svg><use href=\"#spinner\"></use></svg></div>");

        if (!btnInput) {
            $veil.append("<div class=\"underlay\"></div>");
        }

        if ($target.get(0).tagName === "IMG") {
            $target.after($veil);
            $veil.css({ width: $target.width(), height: $target.height() });
            if ($target.parent().css("position") === "static") {
                $target.parent().css("position", "relative");
            }
        } else {
            $target.append($veil);
            if ($target.css("position") === "static") {
                $target.parent().css("position", "relative");
                $target.parent().addClass("veiled");
            }
            if ($target.get(0).tagName === "BODY") {
                $veil.find(".spinner").css("position", "fixed");
            }
        }

        $veil.on("click", function (e) {
            e.stopPropagation();
        });
    }

    /**
     * Remove existing spinner
     * @param  {element} $veil - jQuery pointer to the veil element
     */
    function removeSpinner($veil) {
        if ($veil.parent().hasClass("veiled")) {
            $veil.parent().css("position", "");
            $veil.parent().removeClass("veiled");
        }
        $veil.off("click");
        $veil.remove();
    }

    // element level spinner:
    $.fn.spinner = function () {
        var $element = $(this);
        var Fn = function () {
            this.start = function (btnInput = false) {
                if ($element.length) {
                    addSpinner($element, btnInput);
                }
            };
            this.stop = function () {
                if ($element.length) {
                    var $veil = $(".veil");
                    removeSpinner($veil);
                }
            };
        };
        return new Fn();
    };

    // page-level spinner:
    $.spinner = function () {
        var Fn = function () {
            this.start = function () {
                addSpinner($("body"));
            };
            this.stop = function () {
                removeSpinner($(".veil"));
            };
        };
        return new Fn();
    };
};
