"use strict";

var drawer = require("./components/drawer");
var summaryHelpers = require("storefront/checkout/summary");
var punchCardJsonModel = {
    collapsibleState: {
        punchCardState: "open",
        qualifyingState: "open",
        instructionsState: "open"
    },
    activePunchesAnimated: 0,
    isPunchCardCompleted: false
};


/**
 * Updates the attribute value in a JSON model
 * @param {Object} jsonModel - the JSON model to be updated
 * @param {string} key - the key of the attribute to be updated
 * @param {any} value - the new value for the attribute
 * @returns {Object} - the updated JSON model
 */
function fillJsonModel(jsonModel, key, value) {
    const updateAttribute = (obj, attrKey, attrValue) => {
        for (let prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                if (typeof obj[prop] === "object") {
                    updateAttribute(obj[prop], attrKey, attrValue);
                } else if (prop === attrKey) {
                    obj[prop] = attrValue;
                }
            }
        }
    };

    updateAttribute(jsonModel, key, value);

    return jsonModel;
}

/**
 * Controls the behavior of a spinner based on the time difference
 * @param {number} timeDiff - the time difference in milliseconds
 * @param {HTMLElement} element - the target element to control the spinner
 * @returns {void}
 */
function spinnerController(timeDiff, element, functionToTrigger) {
    let timeToUpdate = 2000;

    if (!element) {
        return;
    }

    if (timeDiff > timeToUpdate) {
        element.spinner().stop();
        if (functionToTrigger) {
            functionToTrigger(element);
        }
    } else {
        setTimeout(function () {
            element.spinner().stop();
            if (functionToTrigger) {
                functionToTrigger(element);
            }
        }, timeToUpdate - (timeDiff));
    }
}

/**
 * Saves data in cookie and session storage
 * @param {string} key - the key to save the data with
 * @param {any} value - the value to be saved
 * @param {number} expirationDays - the number of days until the cookie expires
 * @returns {void}
 */
function saveData(key, value, expirationDays) {
    // Check if the value is a JSON object
    if (typeof value === "object") {
        value = JSON.stringify(value);
    }

    // Save data in cookie
    var date = new Date();
    date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + date.toUTCString();
    document.cookie = key + "=" + encodeURIComponent(value) + ";" + expires + ";path=/";

    // Save data in session storage
    sessionStorage.setItem(key, value);
}

/**
 * Retrieves data from session storage or cookie
 * @param {string} name - the name of the data to retrieve
 * @returns {any} - the retrieved data, or an empty string if not found
 */
function getData(name) {
    const sessionValue = sessionStorage.getItem(name);

    if (sessionValue) {
        try {
            return JSON.parse(sessionValue);
        } catch {
            return sessionValue;
        }
    }

    const cookieValue = document.cookie.split("; ")
        .find(row => row.startsWith(`${name}=`))
        ?.split("=")[1];

    if (cookieValue) {
        try {
            return JSON.parse(decodeURIComponent(cookieValue));
        } catch {
            return decodeURIComponent(cookieValue);
        }
    }

    return "";
}


function updateApproachingDiscounts(approachingDiscounts) {
    var html = "";
    $(".approaching-discounts").empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += "<div class=\"single-approaching-discount text-start\">"
                + item.discountMsg + "</div>";
        });
    }
    $(".approaching-discounts").append(html);
}

function updateAllCartPromotions(data) {
    if (data.promotionsDetails) {
        $(".promo-codes-wrapper").empty().append(data.promotionsDetails.appliedCouponsHTML);
        $(".rewards-codes-wrapper").empty().append(data.loyaltyRewards.rewardsHtml);
        summaryHelpers.updatePromotionsList(data.promotionsDetails.appliedCouponsCodes);
    }

    if (data.approachingDiscounts) {
        summaryHelpers.updateApproachingDiscounts(data.approachingDiscounts);
    }

    if (data.totals && data.totals.orderLevelDiscountsHtml) {
        summaryHelpers.updateOrderLevelDiscounts(data);
    }

    if (data.totals && data.totals.shippingLevelDiscountsHtml) {
        summaryHelpers.updateShippingLevelDiscounts(data);
    }
}

function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = "<div class=\"alert alert-danger alert-dismissible valid-cart-error " +
                "fade show\" role=\"alert\">" +
                "<button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\">" +
                "<span aria-hidden=\"true\">&times;</span>" +
                "</button>" + data.valid.message + "</div>";

            $(".cart-error").append(errorHtml);
        } else {
            $(".cart").empty().append("<div class=\"row\"> " +
                "<div class=\"col-12 text-center\"> " +
                "<h1>" + data.resources.emptyCartMsg + "</h1> " +
                "</div> " +
                "</div>"
            );
            $(".number-of-items").empty().append(data.resources.numberOfItems);
            $(".minicart-quantity").empty().append(data.numItems);
            $(".minicart-link").attr({
                "aria-label": data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $(".minicart .popover").empty();
            $(".minicart .popover").removeClass("show");
        }

        $(".checkout-btn").addClass("disabled");
    } else {
        $(".checkout-btn").removeClass("disabled");
    }
}

function createErrorNotification(message) {
    var errorHtml = "<div class=\"alert alert-danger alert-dismissible valid-cart-error " +
        "fade show\" role=\"alert\">" +
        "<button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\">" +
        "<span aria-hidden=\"true\">&times;</span>" +
        "</button>" + message + "</div>";

    if (!$(".cart-error").children().length) {
        $(".cart-error").append(errorHtml);
    } else {
        $(".cart-error").empty();
        $(".cart-error").append(errorHtml);
    }
}

function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    var message;
    var icon;

    if (data.success) {
        status = "alert-success";
        icon = "<svg id='icon-step-success' class='icon icon-md margin-8-right' fill='none' viewBox='0 0 16 11'><path d='M12.3334 1.66699L5.00002 9.00033L1.66669 5.66699' stroke='#5C8B8D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>";
        message = data.message;
    } else {
        status = "alert-danger";
        icon = "<svg id='icon-alert' class='icon icon-md margin-8-right' viewBox='0 0 16 16' fill='none'> <path fill-rule='evenodd' clip-rule='evenodd' d='M8.00065 14.6654C11.6825 14.6654 14.6673 11.6806 14.6673 7.9987C14.6673 4.3168 11.6825 1.33203 8.00065 1.33203C4.31875 1.33203 1.33398 4.3168 1.33398 7.9987C1.33398 11.6806 4.31875 14.6654 8.00065 14.6654Z' stroke='#7E1D21' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/><path d='M8 5.33203V7.9987' stroke='#7E1D21' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/><ellipse cx='8.00065' cy='10.6667' rx='0.666667' ry='0.666667' fill='#7E1D21'/></svg>";
        message = data.message ? data.message : data.errorMessage;
    }


    if ($(".toast-alert-message").length === 0) {
        $("body").append(
            "<div class='toast-alert-message'></div>"
        );
    }
    $(".toast-alert-message")
        .append("<div class='toast-alert text-center margin-8-vert pdd-8 " + status + "' role='alert'>" + icon + "<span class='text text-center text-sm text-wrap'>" + message + "</span></div>")
        .show();

    setTimeout(function () {
        $(".toast-alert-message").empty().hide();
        if (button && button !== "") {
            button.removeAttr("disabled");
        }
    }, 5000);
}

function verifyCustomSelect(elements) {
    $.each(elements, function (index, element) {
        if ($(element).val() === "") {
            $(element).parents(".form-group").removeClass("floating-label");
            $(element).siblings(".form-control-label").addClass("d-none");
        } else {
            $(element).parents(".form-group").addClass("floating-label");
            $(element).siblings(".form-control-label").removeClass("d-none");
        }
    });
}

// Handles keyboard events and moves focus between first and last focusable elements inside modals.
$(document).on("keydown", function (e) {
    let modalParent =  $(e.target).parents(".modal, .js-location-modal, .popover");

    if (modalParent.length > 0) {

        if (e.keyCode === 9) {
            const focusableElements = modalParent.find("a[href], button:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex='-1'])");
            const firstElement = focusableElements.first();
            const lastElement = focusableElements.last();

            if (e.shiftKey && document.activeElement === firstElement[0]) {
                e.preventDefault();
                lastElement.focus();
            }

            else if (!e.shiftKey && document.activeElement === lastElement[0]) {
                e.preventDefault();
                firstElement.focus();
            }
        }
    }
});

/**
 * Applies a coupon code and updates the cart accordingly
 * @param {Event} e - the event object
 * @param {string} couponCode - the coupon code to apply
 * @returns {boolean} - false to prevent default form submission behavior
 */
function applyCode(couponCode, e) {
    let url = $("[data-apply-url]").attr("data-apply-url")+"?couponCode="+couponCode;
    let button = e ? $(e.target) : "";
    let isCartPage = $(".cart-header").length > 0;

    let errorWrapper = $(`[data-code=${couponCode}]`).parents(".code-wrapper").find(".js-error-wrapper");
    errorWrapper.removeClass("d-flex");
    errorWrapper.children(".js-error-text").empty();

    summaryHelpers.updateAccordionHeight();

    $.ajax({
        url: url,
        type: "GET",
        headers: {
            Accept: "*/*,application/json"
        },
        success: function (data) {
            if (data.error || (data.couponInCartInfo && data.couponInCartInfo.error)) {
                if (data.errorCode === 401) {
                    $(".page").data("refresh", "true");
                    $(".js-login-drawer-open[data-option=login]").trigger("click");

                    return;
                }

                if (data.replaced || (data.couponInCartInfo && data.couponInCartInfo.replaced)) {
                    $("button.js-apply-cart-code").trigger("product:updateCartTotals", data);
                    updateAllCartPromotions(data);

                    validateBasket(data);

                    $(".header-banner").find("[data-coupon-code="+couponCode+"]").text($("[data-applied-text]").data("applied-text"));

                    if (data.lastApplied && data.lastApplied.couponCode) {
                        errorWrapper = $(".promo-codes-wrapper").find(`[data-id=${data.lastApplied.couponCode}]`).parents(".code-wrapper").find(".js-error-wrapper");
                    }

                    errorWrapper.empty().append(data.renderedHtml);
                    errorWrapper.addClass("d-flex");
                } else {
                    displayMessage(data, button);
                }

                window.dispatchEvent(new CustomEvent("promoError", {
                    detail: {
                        promoCode: data.couponCode,
                        type: data.errorMessage
                    }
                }));
            } else {
                $(".coupons-and-promos").empty().append(data.totals.discountsHtml);
                $("button.js-apply-code").trigger("product:updateCartTotals", data);

                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);

                $(".header-banner").find("[data-coupon-code="+couponCode+"]").text($("[data-applied-text]").data("applied-text"));

                updateAllCartPromotions(data);
                summaryHelpers.showNotApplicablePromo(data);
                $("body").trigger("promotion:success", data);

                errorWrapper.removeClass("d-flex");
                errorWrapper.children(".js-error-text").empty();

                window.dispatchEvent(new CustomEvent("promoAdd", {
                    detail: {
                        promoCodes: [
                            {
                                code: data.aPromo.code,
                                name: data.aPromo.name,
                                singleUsePromoCode: data.aPromo.singleUsePromoCode,
                                type: "add from list",
                            }
                        ]
                    }
                }));
            }
            $(".coupon-code-field").val("");
            summaryHelpers.updateAccordionHeight();
            if (!isCartPage) {
                $(".minicart-popover").addClass("promocode-notification");
                $(".minicart .popover-open").trigger("click");
            } else {
                $(".js-promo-code-wrapper").addClass("active");
                $("html, body").animate({
                    scrollTop: $(".js-promo-code-wrapper").offset().top
                }, 100);
            }
            $.spinner().stop();
        },
        error: function (err) {
            $("body").trigger("promotion:error", err);
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.errorMessage);
                $.spinner().stop();
            }
        }
    });
    return false;
}

function callResponsysController(companyTag, url) {
    $.ajax({
        url: url,
        type: "POST",
        headers: {
            Accept: "*/*,application/json"
        },
        data: { companyTag: companyTag },
        success: function (data) {
            displayMessage(data, $(".js-hunt-sweepstakes"));
            return true;
        },
        error: function (data) {
            displayMessage(data, $(".js-hunt-sweepstakes"));
            return false;
        }
    });
}

/**
 * Makes an AJAX POST request to the specified URL to submit a sweepstakes entry.
 * Displays a message based on the response and reloads the page after a delay.
 *
 * @param {Object} sweepstakeEntry - The entry data for the sweepstakes.
 * @param {string} url - The URL to which the AJAX request is sent.
 * @param {Object} sweepsStakeSignup - Additional signup data for the sweepstakes.
 */
function callCheetahController(sweepstakeEntry, url, sweepsStakeSignup) {
    $.ajax({
        url: url,
        type: "GET",
        headers: {
            Accept: "*/*,application/json"
        },
        data: { sweepstakeEntry: sweepstakeEntry, sweepsStakeSignup: sweepsStakeSignup },
        success: function (data) {
            displayMessage(data, $(".js-cheetah-sweepstakes"));
            $("body").trigger("checkboxChanged");
            return true;
        },
        error: function (data) {
            displayMessage(data, $(".js-cheetah-sweepstakes"));
            return false;
        }
    });
}

/**
 * Initializes punch cards by making an AJAX request and updating the DOM
 * @param {string} url - the URL to fetch the punch card data from
 */
function initPunchCards(url) {
    let currentTimeStamp = new Date().getTime();
    let elementSpinner;

    if ($(".js-punch-cards-block").length > 0) {
        elementSpinner = $(".js-punch-cards-block");
    } else if ($(".js-punch-cards-block").length === 0) {
        elementSpinner = $(".js-punch-cards-banner");
    }

    elementSpinner.removeClass("d-none");
    elementSpinner.spinner().start();

    $.ajax({
        type: "get",
        url: url,
        success: function (response) {
            let newCurrentTimeStamp = new Date().getTime();
            let timeDiff = newCurrentTimeStamp - currentTimeStamp;
            let userId = $(".js-punch-card-customer-id").val();
            let punchCardName = $(".js-punch-cards-block").data("name");
            let punchCardBannerName = $(".nav-item .js-punch-cards-banner").data("name");
            let punchCardId = `${punchCardName} - ${userId}`;
            let punchBannerId = `${punchCardBannerName} - ${userId}`;
            let punchCardData = getData(punchCardId);
            let punchBannerData = getData(punchBannerId);
            let newPunchCardData;
            let newPunchBannerData;

            if (punchCardData === "") {
                newPunchCardData = fillJsonModel(punchCardJsonModel);
                saveData(punchCardId, newPunchCardData);
                punchCardData = getData(punchCardId);
            }

            if (punchBannerData === "") {
                newPunchCardData = fillJsonModel(punchCardJsonModel);
                saveData(punchBannerId, newPunchCardData);
                punchBannerData = getData(punchBannerId);
            }

            if (response.punchCardsDetails && response.punchCardsDetails.punchCards.length > 0) {
                $.each(response.punchCardsDetails.punchCards, function (indexInArray, punchCard) {
                    if (punchCard.extra_json.configs.force_accordion_state_main !== false) {
                        newPunchCardData = fillJsonModel(punchCardData, "punchCardState", punchCard.extra_json.configs.force_accordion_state_main);
                        saveData(punchCardId, newPunchCardData);
                        punchCardData = getData(punchCardId);
                    }

                    if (punchCard.extra_json.configs.force_accordion_state_instructions) {
                        newPunchCardData = fillJsonModel(punchCardData, "instructionsState", punchCard.extra_json.configs.force_accordion_state_instructions);
                        saveData(punchCardId, newPunchCardData);
                        punchCardData = getData(punchCardId);
                    }

                    if (punchCard.extra_json.configs.force_accordion_state_qualifiers) {
                        newPunchCardData = fillJsonModel(punchCardData, "qualifyingState", punchCard.extra_json.configs.force_accordion_state_qualifiers);
                        newPunchBannerData = fillJsonModel(punchBannerData, "qualifyingState", punchCard.extra_json.configs.force_accordion_state_qualifiers);
                        saveData(punchCardId, newPunchCardData);
                        saveData(punchBannerId, newPunchBannerData);
                        punchCardData = getData(punchCardId);
                        punchBannerData = getData(punchBannerId);
                    }
                });
            }

            if ($(".js-punch-cards-block").length > 0) {
                $(".js-punch-cards-block").append(response.renderedTemplate);
                var collapsibleElement = $(".js-punch-cards-block").find(".js-qualifying-collapsible");
                $.each(collapsibleElement, function (indexInArray, element) {
                    var collapsibleTarget = $(element).data("target");
                    var newUUID = "qualyfyingCollapsible-" + Math.random().toString(36).substr(2, 9) + Date.now();
                    $(element).addClass("js-punch-card-origin");
                    $(collapsibleTarget).attr("id", newUUID);
                    $(element).attr("data-target", `#${newUUID}`);
                });
            }

            if ($(".js-food-games-banner").length > 0) {
                $(".js-food-games-banner").empty().append(response.renderedBanner);
                $(".js-food-games-banner").removeClass("d-none");
            }

            let punchHeader = $(response.renderedTemplate).find(".js-punch-card-header");
            let punchClone = $(response.renderedTemplate).find(".js-punch-card");
            let punchBannerWrapper = "<div class='punch-banner-wrapper js-punch-cards-banner'></div>";
            let punchCardIdNo = $(".js-pd-punch-card").length > 0 ? $(".js-pd-punch-card").data("punch-card-id-no") : null;

            $.each(punchClone, function (indexInArray) {
                let target = $(".js-" + $(this).data("position"));
                $(punchBannerWrapper).insertBefore(target);
                $($(".js-punch-cards-banner")[indexInArray])
                    .append(punchHeader[indexInArray])
                    .append($(this));

                if ($(this).data("card-theme") !== undefined) {
                    $($(".js-punch-cards-banner")[indexInArray]).addClass("punch-banner-wrapper-" + $(this).data("card-theme"));
                }

                if ($(this).data("position") === "after-rewards") {
                    $($(".js-punch-cards-banner")[indexInArray]).addClass("pdd-16-hor pdd-8-top pdd-16-bot");
                    $(punchHeader[indexInArray]).addClass("margin-8-bot");
                    $(target).addClass("margin-8-top");
                }
            });

            if ($(".js-pd-punch-card").length > 0) {
                let punchCardClass = ".js-punch-card-id-" + punchCardIdNo;
                let pdPunchHeader = $(response.renderedTemplate).find(".js-punch-card-header" + punchCardClass);
                let pdPunchContent = $(response.renderedTemplate).find(".js-punch-card" + punchCardClass);
                $(pdPunchHeader).addClass("margin-8-bot");

                $(".js-pd-punch-card")
                    .append(pdPunchHeader)
                    .append(pdPunchContent);
            }

            $(".nav-item .js-punch-cards-banner").find(".js-qualifying-collapsible").addClass("js-punch-banner-origin");
            $(".nav-item .js-punch-cards-banner").removeClass("d-none");

            if (punchCardData.isPunchCardCompleted === true || punchBannerData.isPunchBannerCompleted === true) {
                newPunchCardData = fillJsonModel(punchCardData, "punchCardState", "closed");
                saveData(punchCardData, newPunchCardData);
                punchCardData = getData(punchCardId);
                $(".animated-bar").addClass("w-100").removeClass("d-none");
                $(".checkpoint").addClass("filled");
                $(".js-punch-img-active").removeClass("d-none");
                $(".js-punch-img-inactive").addClass("d-none");
            }

            setSavedCollapsibleStates(".js-punch-cards-block", punchCardData);
            setSavedCollapsibleStates(".js-punch-cards-banner", punchBannerData);
            spinnerController(timeDiff, $(".js-punch-cards-block"), startBarAnimation);
            spinnerController(timeDiff, $(".nav-item .js-punch-cards-banner"), startBarAnimation);
        },
        error: function () {
            elementSpinner.spinner().stop();
        }
    });
}

function saveCollapsibleState(element) {
    const elementData = $(element).data("element");
    const elementName = $(element).hasClass("js-punch-card-origin") ? $(".js-punch-cards-block").data("name") : $(".nav-item .js-punch-cards-banner").data("name");
    const dataId = `${elementName} - ${$(".js-punch-card-customer-id").val()}`;
    let punchCardData = getData(dataId);
    let collapsibleData = punchCardData["collapsibleState"];
    let newData;

    let newState = "closed";
    if (punchCardData !== "") {
        newState = collapsibleData[elementData] === "open" ? "closed" : "open";
        newData = fillJsonModel(punchCardData, elementData, newState);
    } else {
        newData = fillJsonModel(punchCardJsonModel, elementData, newState);
    }

    saveData(dataId, newData);

}
/**
 * Sets the saved collapsible states based on the stored cookies
 */
function setSavedCollapsibleStates(element, data) {
    const collapsibleStates = data.collapsibleState || {};

    const punchCardState = collapsibleStates.punchCardState || "open";
    const instructionsState = collapsibleStates.instructionsState || "open";
    const qualifyingState = collapsibleStates.qualifyingState || "open";

    const punchCardBody = $(element).find(".js-punch-card-body");
    const instructionsBody = $(element).find(".js-instructions-body");
    const qualifyingBody = $(element).find(".js-qualifying-body");

    switch (punchCardState) {
        case "open":
            punchCardBody.addClass("show").removeClass("collapse");
            $(element).find(".js-punch-card-collapsible").removeClass("collapsed");
            break;
        case "closed":
            punchCardBody.removeClass("show").addClass("collapse");
            $(element).find(".js-punch-card-collapsible").addClass("collapsed");
            break;
        default:
            break;
    }

    switch (instructionsState) {
        case "open":
            instructionsBody.addClass("show").removeClass("collapse");
            $(element).find(".js-instructions-collapsible").removeClass("collapsed");
            break;
        case "closed":
            instructionsBody.removeClass("show").addClass("collapse");
            $(element).find(".js-instructions-collapsible").addClass("collapsed");
            break;
        default:
            break;
    }

    switch (qualifyingState) {
        case "open":
            qualifyingBody.addClass("show").removeClass("collapse");
            $(element).find(".js-qualifying-collapsible").removeClass("collapsed");
            break;
        case "closed":
            qualifyingBody.removeClass("show").addClass("collapse");
            $(element).find(".js-qualifying-collapsible").addClass("collapsed");
            break;
        default:
            break;
    }
}

/**
 * Starts the animation of a progress bar
 * @param {HTMLElement} element - The target element containing the progress bar
 */
function startBarAnimation(element) {
    const numberOfElements = $(element).length;
    const bars = $(element).find(".animated-bar");
    const elementName = $(element).data("name");
    const punchCardDataId = `${elementName} - ${$(".js-punch-card-customer-id").val()}`;
    let punchCardData = getData(punchCardDataId);
    let animatedPunches = punchCardData["activePunchesAnimated"];
    let currentIndex = 0;
    let newState = 0;
    let newData;

    if (animatedPunches !== "") {
        currentIndex = animatedPunches !== "0" ? (animatedPunches / numberOfElements) : 0;
    } else {
        newData = fillJsonModel(punchCardData, "activePunchesAnimated", newState);
        saveData(punchCardDataId, newData);
    }

    if (bars.length >= $(element).find(".checkpoint").length && animatedPunches >= $(element).find(".checkpoint").length) {
        bars.addClass("w-100").removeClass("d-none");
        $(element).find(".checkpoint").addClass("filled");
        $(element).find(".js-punch-img-active").removeClass("d-none");
        $(element).find(".js-punch-img-inactive").addClass("d-none");
        newData = fillJsonModel(punchCardData, "punchCardState", "closed");
        saveData(punchCardDataId, newData);

        if (elementName !== "undefined" && elementName !== undefined) {
            newData = fillJsonModel(punchCardData, "isPunchCardCompleted", true);
            saveData(punchCardDataId, newData);
        }
        return;
    }


    bars.sort((a, b) => $(a).index() - $(b).index());

    for (let index = 0; index < currentIndex; index++) {
        const element = bars[index];
        const spaceBar = $(element).parents(".space-bar");
        const previousCheckPoint = spaceBar.prev(".checkpoint");
        const nextCheckPoint = spaceBar.next(".checkpoint");

        $(element).addClass("w-100").removeClass("d-none");
        previousCheckPoint.addClass("filled");
        previousCheckPoint.find(".js-punch-img-active").removeClass("d-none");
        previousCheckPoint.find(".js-punch-img-inactive").addClass("d-none");
        nextCheckPoint.addClass("filled");
        nextCheckPoint.find(".js-punch-img-active").removeClass("d-none");
        nextCheckPoint.find(".js-punch-img-inactive").addClass("d-none");
    }

    animateBar();

    function animateBar() {
        if (currentIndex >= bars.length) {
            return;
        }

        const elem = $(bars[currentIndex]);
        const spaceBar = elem.parents(".space-bar");
        const previousCheckPoint = spaceBar.prev(".checkpoint");
        const nextCheckPoint = spaceBar.next(".checkpoint");
        const id = setInterval(frame, 0.1);

        elem.css("width", "0.1%");

        if (elem.hasClass("d-none")) {
            if (previousCheckPoint.length > 0) {
                previousCheckPoint.addClass("filled");
                previousCheckPoint.find(".js-punch-img-active").removeClass("d-none");
                previousCheckPoint.find(".js-punch-img-inactive").addClass("d-none");
            }
            elem.removeClass("d-none");
        }

        function frame() {
            elem.addClass("w-100");
            clearInterval(id);
            elem.dequeue();
            setTimeout(() => {
                currentIndex++;
                newData = fillJsonModel(punchCardData, "activePunchesAnimated", currentIndex);
                saveData(punchCardDataId, newData);
                animateBar();
                if (nextCheckPoint.length > 0) {
                    nextCheckPoint.addClass("filled");
                    nextCheckPoint.find(".js-punch-img-active").removeClass("d-none");
                    nextCheckPoint.addClass("is-transformed");
                    nextCheckPoint.find(".js-punch-img-inactive").addClass("d-none");
                }
                setTimeout(() => {
                    nextCheckPoint.removeClass("is-transformed");
                }, 500);
                if (currentIndex === bars.length) {
                    setTimeout(() => {
                        nextCheckPoint.addClass("is-transformed");
                    }, 1000);
                    setTimeout(() => {
                        nextCheckPoint.removeClass("is-transformed");
                    }, 1500);
                    nextCheckPoint.find(".js-complete-punch-img").removeClass("d-none");
                    setTimeout(() => {
                        nextCheckPoint.find(".js-complete-punch-img").addClass("d-none");
                    }, 3000);

                    if (bars.length >= $(element).find(".checkpoint").length) {
                        newData = fillJsonModel(punchCardData, "isPunchCardCompleted", true);
                        saveData(punchCardDataId, newData);
                    }
                }
            }, 1500);
        }
    }
}

/**
* Loads and replaces script elements with a "data-lazy-src" attribute in the specified element.
* This function finds all script elements within the given element that have a "data-lazy-src" attribute,
* creates new script elements with the source specified in the "data-lazy-src" attribute,
* and copies all attributes from the original script elements to the new script elements.
* Finally, it replaces the original script elements with the new script elements.
*
* @param {HTMLElement} element - The DOM element containing script elements to be lazily loaded.
*/
function loadContent(element) {
    var scriptElements = $(element).find("[data-lazy-src]");

    $.each(scriptElements, function (indexInArray, scriptElement) {
        var newScript = document.createElement("script");
        newScript.src = $(scriptElement).data("lazy-src");

        $.each(scriptElement.attributes, function () {
            newScript.setAttribute(this.name, this.value);
        });

        $(scriptElement).replaceWith(newScript);
    });
}

/**
 * Initializes event listeners and handles various actions on the page
 */
module.exports = function () {
    $("body").on("click", ".js-apply-code", function (e) {
        e.preventDefault();
        $.spinner().start();
        let couponCode = $(e.target).attr("data-coupon-code") ? $(e.target).attr("data-coupon-code") : "";
        applyCode(couponCode, e);
    });

    $("body").on("click", ".js-buyagain-alert", function (e) {
        e.preventDefault();
        let url = $(this).attr("data-url");
        let target = $(this).attr("data-target");
        let productId = $(this).attr("data-product-line-id");
        let clientSideValidation = require("./components/clientSideValidation");

        $.ajax({
            url: url,
            data: "productId=" + productId,
            type: "GET",
            headers: {
                Accept: "*/*,application/json"
            },
            success: function (data) {
                $(".js-notify-drawer").html(data);
                drawer.openDrawer(target);
                clientSideValidation.invalid();
            }
        });
        return false;
    });

    $("body").on("click", ".js-promo-detail", function (e) {
        e.preventDefault();
        let id = $(this).attr("data-id");
        let fromCart = $(this).attr("data-from-cart");
        let type = $(this).attr("data-type");
        let details = $(this).attr("data-details");
        let url = $("[data-detail-url]").attr("data-detail-url")+"?promotionId="+id;
        let clickedBtn = $(this);
        url += fromCart ? "&fromCart=true" : "";

        $.ajax({
            url: url,
            type: "GET",
            headers: {
                Accept: "*/*,application/json"
            },
            success: function (data) {
                if (type === "drawer") {
                    $(".js-promotion-drawer").html(data);
                    if (details != "") {
                        $(".js-promotion-drawer").find(".drawer-content").html(details);
                    }
                    drawer.openDrawer(".js-promotion-drawer");
                }
                else {
                    let htmlDetail = $(`
                        <div class="modal fade" id="showPromotionDetailModal" tabindex="-1" role="dialog">
                            ${data}
                        </div>
                    `);

                    htmlDetail.on("hidden.bs.modal", event => {
                        $(event.target).remove();
                        $("html, body").removeClass("no-scroll-top");

                        if (clickedBtn && clickedBtn.length > 0) {
                            clickedBtn.focus();
                        }
                    });

                    htmlDetail.modal();
                }
            }
        });
        return false;
    });

    $("body").on("click", ".js-hunt-sweepstakes", function (e) {
        e.preventDefault();
        var companyTag = $(this).data("hunt-sweepstakes-tag");
        var url = $(".js-sweepstakes-url").data("sweepstakes-url");

        if ($(".logged-user").length > 0) {
            callResponsysController(companyTag, url);
        } else {
            window.sweepstakeOnLogin = {
                companyTag: companyTag
            };
            $(".js-login-drawer-open").click();
        }
    });

    $("body").on("click", ".js-cheetah-sweepstakes", function (e) {
        const $this = $(this);
        const sweepstakeEntry = $this.data("cheetah-sweepstakes-tag");
        const sweepsStakeSignup = $this.data("cheetah-sweepstakes-signup");
        const url = $(".js-cheetah-sweepstakes-url").data("cheetah-sweepstakes-url");
        const sweepsStakeCheckbox = $(`.pseudo-checkbox[data-cheetah-sweepstakes-tag=${sweepstakeEntry}]`);
        const isCheckbox = $this.hasClass("pseudo-checkbox");
        const isCtaLink = isCheckbox && $(e.target).is("a");
        if (!isCtaLink) {
            e.preventDefault();

            // Validate checkbox state
            if (sweepsStakeCheckbox.length > 0 && !sweepsStakeCheckbox.hasClass("selected") && !isCheckbox) {
                sweepsStakeCheckbox.addClass("is-invalid");
                return;
            }

            // Check if user is authenticated
            if ($(".js-is-authenticated").length > 0 && $(".js-is-authenticated").val() === "true") {
                callCheetahController(sweepstakeEntry, url, sweepsStakeSignup);

                // Toggle signup state for all elements with the same tag
                $(`[data-cheetah-sweepstakes-tag=${sweepstakeEntry}]`).each(function () {
                    const $element = $(this);
                    $element.data("cheetah-sweepstakes-signup", !sweepsStakeSignup).attr("data-cheetah-sweepstakes-signup", !sweepsStakeSignup);
                });

                // Handle UI changes based on signup state
                if (!sweepsStakeSignup && isCheckbox) {
                    $(`.d-none[data-cheetah-sweepstakes-tag=${sweepstakeEntry}]`).removeClass("d-none");
                    $this.removeClass("update-text");
                    $this.removeClass("js-cheetah-sweepstakes");
                } else if (sweepsStakeSignup && !isCheckbox) {
                    $(`.js-cheetah-sweepstakes[data-cheetah-sweepstakes-tag=${sweepstakeEntry}]`).addClass("d-none");
                    if (sweepsStakeCheckbox.length > 0) {
                        sweepsStakeCheckbox.addClass("js-cheetah-sweepstakes");
                        sweepsStakeCheckbox.addClass("update-text");
                    }
                }
            } else {
                // Store state for post-login action
                window.cheetahSweepstakeOnLogin = {
                    sweepstakeEntry: sweepstakeEntry,
                    sweepsStakeSignup: sweepsStakeSignup
                };
                $(".js-login-drawer-open:first").trigger("click");
            }
        }
    });

    $("body").on("click", ".js-sign-in-redirect", function (e) {
        e.preventDefault();
        $(".js-login-tab ").click();
    });

    $(window).on("popstate", function () {
        window.location.reload();
    });

    verifyCustomSelect($(".js-custom-select"));

    $("body").on("change", ".js-custom-select", function () {
        verifyCustomSelect($(this));
    });

    $("body").on("click", ".js-passwordless-button", function () {
        $("#passwordless-form-email").removeClass("is-invalid");
    });

    setTimeout(() => {
        if (window.location.href.includes("password-reset")) {
            $(".js-reset-drawer-open").trigger("click");
        }

        if (window.location.href.includes("token-error")) {
            $(".js-token-error").removeClass("d-none");
        }

        if (window.location.href.includes("join")) {
            $(".js-login-drawer-open[data-option=join]").trigger("click");
        }

        if (window.location.href.includes("login")) {
            $(".js-login-drawer-open[data-option=login]").trigger("click");
        }

        if (window.location.href.includes("apply-coupon")) {
            let urlParams = new URLSearchParams(window.location.search);
            let $couponCode = urlParams.get("apply-coupon");
            applyCode($couponCode);
        }
    }, 200);

    let $appliedCoupons = $("[data-applied-ids]").data("applied-ids");
    if ($appliedCoupons) summaryHelpers.updatePromotionsList($appliedCoupons);

    $("body").on("click", ".js-collapsible", function () {
        saveCollapsibleState($(this));
    });

    $(document).ready(function () {
        var toastData = $(".js-toast-message").val() ? JSON.parse($(".js-toast-message").val()) : null;
        var hasPunchCardsURL = $(".js-has-punch-cards").length > 0 ? $(".js-has-punch-cards").data("url") : null;
        var punchCardsDetailsURL = $(".js-punch-card-details").length > 0 ? $(".js-punch-card-details").data("url") : null;
        var hasPunchCards = $(".js-has-punch-cards").length > 0 ? $(".js-has-punch-cards").val() : "null";
        var isAuthenticated = $(".js-is-authenticated").length > 0 ? $(".js-is-authenticated").val() : "false";
        const lazyElements = $(".js-replace-script");

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    loadContent(entry.target);

                    observer.unobserve(entry.target);
                }
            });
        });

        if (lazyElements && lazyElements.length > 0) {
            $.each(lazyElements, function (indexInArray, lazyElement) {
                observer.observe(lazyElement);
            });
        }


        if (toastData !== null) {
            displayMessage(toastData);
        }

        if (isAuthenticated === "true") {
            if (hasPunchCards === "null") {
                $.ajax({
                    type: "get",
                    url: hasPunchCardsURL,
                    dataType: "json",
                    success: function (response) {
                        if (response.hasCards === true) {
                            $(".js-has-punch-cards").val("true");
                            initPunchCards(punchCardsDetailsURL);
                        } else {
                            return;
                        }
                    }
                });
            } else if (hasPunchCards === "true") {
                initPunchCards(punchCardsDetailsURL);
            }
        }
        if ($(".js-secondary-nav").length > 0) {
            $(".js-first-navbar").append($(".js-secondary-nav").children());
        }
    });
};
