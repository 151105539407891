"use strict";

var clientSideValidation = require("storefront/components/clientSideValidation");

/**
 * Generates the modal window on the first call.
 */
function getModalHtmlElement(isCartModal) {
    if ($("#inStoreInventoryModal").length !== 0) {
        $("#inStoreInventoryModal").remove();
    }

    var modalClass = isCartModal ? "cart-store-modal" : "pdp-store-modal";

    var htmlString = "<!-- Modal -->"
        + "<div class=\"js-location-modal location-modal location-modal-centered pr-0 show "
        + modalClass
        +  "\" id=\"inStoreInventoryModal\" data-dynamic-update=\"true\" role=\"dialog\">"
        + "<div class=\"modal-dialog in-store-inventory-dialog\">"
        + "<!-- Modal content-->"
        + "<div class=\"modal-content\">"
        + "</div>"
        + "</div>"
        + "</div>";
    $("body").append(htmlString);
    $("#inStoreInventoryModal").modal("show");
}

/**
 * Replaces the content in the modal window with find stores components and
 * the result store list.
 * @param {string} pid - The product ID to search for
 * @param {number} quantity - Number of products to search inventory for
 * @param {number} selectedPostalCode - The postal code to search for inventory
 * @param {number} selectedRadius - The radius to search for inventory
 */
function fillModalElement(pid, quantity, selectedPostalCode, selectedRadius, selectedStore, pidsObject) {
    var requestData = {};

    if (pidsObject) {
        requestData.products = pidsObject;
    } else {
        requestData.products = pid + ":" + quantity;
    }

    if (selectedRadius) {
        requestData.radius = selectedRadius;
    }

    if (selectedPostalCode) {
        requestData.postalCode = selectedPostalCode;
    }

    var $modalElem = $("#inStoreInventoryModal");
    var ajaxURL = $("#js-cart-links").data("action-url") || $(".btn-get-in-store-inventory").data("action-url");

    $modalElem.spinner().start();
    $.ajax({
        url: ajaxURL,
        data: requestData,
        method: "GET",
        success: function (response) {
            $modalElem.find(".modal-content").empty();
            $modalElem.find(".modal-content").html(response.storesResultsHtml);

            $(".btn-storelocator-search").attr("data-search-pid", pid);

            if (selectedRadius) {
                $("#radius").val(selectedRadius);
            }

            if (selectedPostalCode) {
                $("#store-postal-code").val(selectedPostalCode);
            }

            if (!$(".results").data("has-results")) {
                $(".store-locator-no-results").show();
            }

            if (selectedStore) {
                $modalElem.find(".js-store-item[data-id='" + selectedStore + "']").addClass("store-item-selected");
            }

            clientSideValidation.invalid();
            clientSideValidation.buttonClick();
            $modalElem.modal("show");
            $modalElem.spinner().stop();
        },
        error: function () {
            var modalContentClone = $($(".js-location-modal")[0]).find(".modal-content").clone();
            $modalElem.find(".modal-content").replaceWith(modalContentClone);
            $modalElem.spinner().stop();
        }
    });
}

module.exports = {
    fillModalElement,
    getModalHtmlElement
};
