"use strict";

let processInclude = require("./util");
processInclude(require("./product/wishlistHeart"));
processInclude(require("./product/addToCart"));

$(document).on("click", ".js-swatch", function (e) {
    e.preventDefault();

    const $el = $(this);

    if ($el.hasClass("selected")) {
        return;
    }

    const $productTile = $el.parents(".product-tile");
    const $tileImage = $productTile.find(".tile-image");
    const $altTileImage = $productTile.find(".tile-alt-image");
    $el.addClass("selected");
    $el.siblings(".swatch-circle").removeClass("selected");
    $tileImage.attr("src", $el.data("product-image-url"));
    $tileImage.attr("title", $el.data("product-image-title"));
    $tileImage.attr("alt", $el.data("product-image-alt"));

    if ($altTileImage) {
        $altTileImage.attr("alt", $el.data("product-alt-image-alt"));
        $altTileImage.attr("src", $el.data("product-alt-image-url"));
        $altTileImage.attr("title", $el.data("product-alt-image-title"));
    }

    // update product tile links
    $productTile.find(".image-container a:first").attr("href", $el.attr("href"));
    $productTile.find(".pdp-link .link").attr("href", $el.attr("href"));
    $productTile.find(".button-addtocart").attr("href", $el.attr("href"));
});
