"use strict";

var debounce = require("lodash/debounce");
var endpoint = $(".suggestions-wrapper").data("url");
var minChars = $(".suggestions-wrapper").data("min-characters");
var UP_KEY = 38;
var DOWN_KEY = 40;
var DIRECTION_DOWN = 1;
var DIRECTION_UP = -1;


/**
* Prevents scrolling on the page
*/
function preventScroll(event) {
    event.preventDefault();
}

/**
* Disables scrolling on the page
*/
function disableScroll() {
    window.oldScrollPos = window.oldScrollPos ? window.oldScrollPos : $("html").scrollTop();

    $("html").addClass("prevent-scroll");
    $(".page").addClass("prevent-scroll").scrollTop(window.oldScrollPos);

    const element = document.getElementById("scroll-page");

    element.addEventListener("touchmove", preventScroll, { passive: false });
    element.addEventListener("wheel", preventScroll, { passive: false });
}

/**
* Enables scrolling on the page
*/
function enableScroll() {
    $(".page").removeClass("prevent-scroll");
    $("html").removeClass("prevent-scroll").scrollTop(window.oldScrollPos);
    const element = document.getElementById("scroll-page");

    delete window.oldScrollPos;
    element.removeEventListener("touchmove", preventScroll, { passive: false });
    element.removeEventListener("wheel", preventScroll, { passive: false });
}

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    let $block;

    if (isMobileSearch()) {
        $block = $(".suggestions-wrapper");
    } else {
        $block = $(scope).siblings(".suggestions-wrapper");
    }
    return $block;
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch() {
    return $("body").innerWidth() < 720;
}

/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals() {
    $("body").removeClass("modal-open");
    $("header").siblings().attr("aria-hidden", "false");
    $(".suggestions").removeClass("modal");
}

/**
 * Apply modal classes needed for mobile suggestions
 *
 * @param {Object} scope - Search input field DOM element
 */
function applyModals(scope) {
    if (isMobileSearch()) {
        $("body").addClass("modal-open");
        $("header").siblings().attr("aria-hidden", "true");
        getSuggestionsWrapper(scope).find(".suggestions").addClass("modal");
    }
}

/**
 * Tear down Suggestions panel
 */
function tearDownSuggestions() {
    $("input.search-field").val("");
    clearModals();
    $(".search-mobile .suggestions").unbind("scroll");
    $(".suggestions-wrapper").empty();
}

/**
 * Toggle search field icon from search to close and vice-versa
 *
 * @param {string} action - Action to toggle to
 */
function toggleSuggestionsIcon(action) {
    var mobileSearchIcon = ".search-mobile button.";
    var iconSearch = "fa-search";
    var iconSearchClose = "fa-close";

    if (action === "close") {
        $(mobileSearchIcon + iconSearch).removeClass(iconSearch).addClass(iconSearchClose).attr("type", "button");
    } else {
        $(mobileSearchIcon + iconSearchClose).removeClass(iconSearchClose).addClass(iconSearch).attr("type", "submit");
    }
}

/**
 * Determines whether the "More Content Below" icon should be displayed
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function handleMoreContentBelowIcon(scope) {
    if (($(scope).scrollTop() + $(scope).innerHeight()) >= $(scope)[0].scrollHeight) {
        $(".more-below").fadeOut();
    } else {
        $(".more-below").fadeIn();
    }
}

/**
 * Positions Suggestions panel on page
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function positionSuggestions(scope) {
    var outerHeight;
    var $scope;
    var $suggestions;
    var top;

    if (isMobileSearch()) {
        $scope = $(scope);
        top = $scope.offset().top;
        outerHeight = $scope.outerHeight();
        $suggestions = getSuggestionsWrapper(scope).find(".suggestions");
        $suggestions.css("top", top + outerHeight);

        handleMoreContentBelowIcon(scope);

        // Unfortunately, we have to bind this dynamically, as the live scroll event was not
        // properly detecting dynamic suggestions element's scroll event
        $suggestions.scroll(function () {
            handleMoreContentBelowIcon(this);
        });
    }
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    var $suggestionsWrapper = getSuggestionsWrapper(this).empty();

    $.spinner().stop();

    if (typeof (response) !== "object") {
        $suggestionsWrapper.append(response).show();
        $("header").addClass("header-above");
        positionSuggestions(this);

        if (isMobileSearch()) {
            toggleSuggestionsIcon("close");
            applyModals(this);
        }

        // Trigger screen reader by setting aria-describedby with the new suggestion message.
        var suggestionsList = $(".suggestions .suggestions-item");
        if (suggestionsList.length > 0) {
            var searchResultsCount = suggestionsList.length;
            $(".modal-background").show();
            $("input.search-field").attr("aria-describedby", searchResultsCount);
            $("#search-results-message").text(searchResultsCount + " search results found").attr("aria-hidden", "false");
        } else {
            $(".modal-background").hide();
        }

        if (window.innerWidth < 992) {

            var $block = $(".suggestions");
            var $contentAbove = $(".header-nav");
            suggestionsList.length > 0 ? $block.css("max-height", "calc(100% - " + $contentAbove.outerHeight() + "px)") : $block.css("max-height", "0px");
            $block.css("top", $contentAbove.outerHeight() + "px");

            $(".modal-background").hide();
            $("body").append($suggestionsWrapper);
            $(".suggestions").addClass("d-block");
        }
    } else {
        $(".js-a-search-form").append($suggestionsWrapper);
        $suggestionsWrapper.hide();
        $(".modal-background").hide();
        $("header").removeClass("header-above");
        $("input.search-field").removeAttr("aria-describedby");
        $("#search-results-message").empty().attr("aria-hidden", "true");
        $(".suggestions").removeClass("d-block");
    }
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    if ($(scope).val().length >= minChars) {
        $(".search-form").spinner().start(true);
        if (window.innerWidth < 992) {
            disableScroll();
        }
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: "GET",
            success: processResponse,
            error: function () {
                $.spinner().stop();
            }
        });
    } else if ($(scope).val().length > 0 && $(scope).val().length < 3 || $(scope).val().length === 0) {
        $(".modal-background").hide();
        if (window.innerWidth < 992) {
            enableScroll();
        }
        $(".suggestions").hide();
        $("input.search-field").removeAttr("aria-describedby");
        $("#search-results-message").empty().attr("aria-hidden", "true");
    } else {
        toggleSuggestionsIcon("search");
        clearModals();
        getSuggestionsWrapper(scope).empty();
        $("input.search-field").removeAttr("aria-describedby");
        $("#search-results-message").empty().attr("aria-hidden", "true");
        $(".modal-background").hide();
    }
}

/**
 * Handle Search Suggestion Keyboard Arrow Keys
 *
 * @param {Integer} direction takes positive or negative number constant, DIRECTION_UP (-1) or DIRECTION_DOWN (+1)
 */
function handleArrow(direction) {
    // get all li elements in the suggestions list
    var suggestionItem = $(".js-sugg-highlight");
    if (suggestionItem.filter(".selected").length === 0) {
        $(suggestionItem[0]).addClass("selected").attr("aria-selected", true);
        $("input.search-field").each(function () {
            $(this).attr("aria-activedescendant", suggestionItem.first()[0].id);
        });
    } else {
        $(suggestionItem).each(function (index) {
            var idx = index + direction;
            if ($(this).hasClass("selected")) {
                $(this).removeClass("selected");
                $(this).attr("aria-selected", false);
                $(this).css("border", "none");
                if (suggestionItem.eq(idx).length !== 0) {
                    suggestionItem.eq(idx).addClass("selected");
                    suggestionItem.eq(idx).attr("aria-selected", true);
                    $(this).attr("aria-selected", false);
                    $("input.search-field").each(function () {
                        $(this).attr("aria-activedescendant", suggestionItem.eq(idx)[0].id);
                    });
                } else {
                    suggestionItem.first().addClass("selected");
                    suggestionItem.first().attr("aria-selected", true);
                    $("input.search-field").each(function () {
                        $(this).attr("aria-activedescendant", suggestionItem.first()[0].id);
                    });
                }
                return false;
            }
            return true;
        });
    }
}

$(document).on("keydown", function (e) {
    var selectedElement = $(".js-sugg-highlight.selected");
    if (e.key === "Enter") {
        if (selectedElement.length !== 0) {
            e.preventDefault();
            if ($(selectedElement).children(".js-a-suggestion-click").length > 0) {
                $(selectedElement).children(".js-a-suggestion-click").attr("href");
                window.location = $(selectedElement).children(".js-a-suggestion-click").attr("href");
            }
        }
    }
    if (e.which === 27) {
        if ($(".suggestions").is(":visible")) {
            $(".modal-background").trigger("click");
            $(".js-search-field").blur();
        }
    }
});

module.exports = function () {
    $("form[name=\"simpleSearch\"]").submit(function (e) {
        var suggestionsList = $(".suggestions .item");
        if (suggestionsList.filter(".selected").length !== 0) {
            e.preventDefault();
            suggestionsList.filter(".selected").find("a")[0].click();
        }
    });

    $("input.search-field").each(function () {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        var debounceSuggestions = debounce(getSuggestions, 300);
        $(this).on("keyup focus", function (e) {
            // Capture Down/Up Arrow Key Events
            switch (e.which) {
                case DOWN_KEY:
                    handleArrow(DIRECTION_DOWN);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                case UP_KEY:
                    handleArrow(DIRECTION_UP);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                default:
                    debounceSuggestions(this, e);
            }
        });
    });

    $("body").on("click", function (e) {
        if (!$(".suggestions").has(e.target).length && $(".suggestions").is(":visible") && !$(e.target).hasClass("search-field")) {
            $(".suggestions").hide();
            $("header").removeClass("header-above");

            if (window.innerWidth < 992) {
                enableScroll();
                $(".suggestions").removeClass("d-block");
            }

            if (!$(".js-navbar-toggler").has(e.target).length) {
                $(".modal-background").hide();
            }
        }
    });

    $("body").on("click touchend", ".search-mobile button.fa-close", function (e) {
        e.preventDefault();
        $(".suggestions").hide();
        $(".modal-background").hide();
        $("header").removeClass("header-above");
        toggleSuggestionsIcon("search");
        tearDownSuggestions();
    });

    $(".reset-button").on("click", function () {
        $(this).toggleClass("d-none");
        $(".icon-search").toggleClass("d-none");
        $(".js-search-field").val("");
        if (window.innerWidth < 992) {
            enableScroll();
        }
    });

    $(".js-search-field").on("focus", function () {
        $(".icon-search").addClass("d-none");
        $(".reset-button").removeClass("d-none");
        $("#search-assistive-text").attr("aria-hidden", false);
    });

    $(".js-search-field").on("blur", function () {
        $("#search-assistive-text").attr("aria-hidden", true);
    });

    $("body").on("click", function (e) {
        if ($(e.target).closest(".search-form").length === 0) {
            $(".icon-search").removeClass("d-none");
            $(".reset-button").addClass("d-none");
        }
    });
};
