"use strict";

/**
 * Updates rewards using an AJAX request
 * @param {string} template - the reward template
 * @returns {Promise} - a promise that resolves with the response or rejects with an error
 */
function updateRewards(template) {
    return new Promise(function (resolve, reject) {
        let url = $(".js-reward-update-url").val();

        let data = {
            template: template,
        };

        $.ajax({
            type: "get",
            url: url,
            data: data,
            dataType: "JSON",
            success: function (response) {
                resolve(response);
            },
            error: function (error) {
                reject(error);
            }
        });
    });
}

/**
 * Manages the update of rewards based on a given template
 * @param {string} template - the template to be used for rewards update
 */
function manageRewardsUpdate(template) {
    let isCartRewards = template === "cart/cartCodesWrapper";
    let isUpdateNeeded = $(".js-has-new-offers").val();
    let rewardsBlock = isCartRewards ? $(".js-cart-rewards") : $(".js-rewards-block-wrapper");
    let rewardsCartWrapper = $(".reward-description");
    let availableRewards = isCartRewards ? $(".rewards-codes-wrapper") : $(".js-rewards-block").find(".available-rewards-card");

    if (isUpdateNeeded === "true") {
        let currentTimeStamp = new Date().getTime();
        let elementSpinner = isCartRewards ? rewardsCartWrapper : $(".js-rewards-block");

        availableRewards.css("opacity", 0);
        elementSpinner.spinner().start();

        updateRewards(template)
            .then(function (response) {
                let newCurrentTimeStamp = new Date().getTime();
                let timeDiff = newCurrentTimeStamp - currentTimeStamp;

                rewardsBlock.empty().html(response.renderedTemplate);

                if ($(".available-rewards-card").length) {
                    let $availableRewardsCard = $(".available-rewards-card");
                    let $availableRewardsCardItems = $availableRewardsCard.find(".available-rewards-card-item");
                    if ($availableRewardsCardItems.length > 4) {
                        $(".js-view-all").removeClass("d-none");
                    }
                }

                spinnerController(timeDiff, elementSpinner, availableRewards);
            })
            .catch(function (error) {
                console.error(error);
                availableRewards.css("opacity", 1);
                elementSpinner.spinner().stop();
            });
    }
}

/**
 * Controls the behavior of a spinner based on the time difference
 * @param {number} timeDiff - the time difference in milliseconds
 * @param {jQuery} element - the target element to apply the spinner on
 * @param {jQuery} elementOpacity - the target element to apply the opacity on
 * @returns {void}
 */
function spinnerController(timeDiff, element, elementOpacity) {
    let timeToUpdate = 2000;

    if (timeDiff > timeToUpdate) {
        elementOpacity.css("opacity", 1);
        element.spinner().stop();
    } else {
        setTimeout(function () {
            elementOpacity.css("opacity", 1);
            element.spinner().stop();
        }, timeToUpdate - (timeDiff));
    }
}

module.exports = {
    updateRewards: updateRewards,
    manageRewardsUpdate: manageRewardsUpdate
};
