"use strict";

function parsePromoHtml(html) {
    var $html = $("<div>").append($.parseHTML(html));

    var body = $html.find(".cart-promo-details");

    return { body: body};
}

module.exports = parsePromoHtml;
