let base = require("base/checkout/summary");

function updateColoradoRetailFee(totals) {
    const hasRetailFee = totals ? totals.deliveryFeeInfo.rate : false;
    $(".estimate-flat-fee").toggleClass("d-none", !hasRetailFee);
    $(".co-state-rate").empty().append(totals && totals.deliveryFeeInfo.rate);
}

base.updateTotals = function (totals) {
    $(".shipping-cost").text(totals.totalShippingCost);
    $(".tax-total").text(totals.totalTax);
    $(".sub-total").text(totals.subTotal);
    $(".grand-total-sum").text(totals.grandTotal);

    if (totals.orderLevelDiscountTotal.value > 0) {
        $(".order-discount").removeClass("hide-order-discount");
        $(".order-discount-total").text("- " + totals.orderLevelDiscountTotal.formatted);
    } else {
        $(".order-discount").addClass("hide-order-discount");
    }

    if (totals.shippingLevelDiscountTotal.value > 0) {
        $(".shipping-discount").removeClass("hide-shipping-discount");
        $(".shipping-discount-total").text(totals.shippingLevelDiscountTotal.formatted);
    } else {
        $(".shipping-discount").addClass("hide-shipping-discount");
    }

    $(".surcharge-cost").empty().parents(".js-total-surcharge").toggleClass("d-none", totals.totalSurchargeCost.value <= 0);
    if (totals.totalSurchargeCost) {
        $(".surcharge-cost").append(totals.totalSurchargeCost.formatted);
    }

    updateColoradoRetailFee(totals);
};

base.updateSummaryPromotions = function (data) {
    if (!data) {
        return;
    }

    if (data.promotionsDetails) {
        updatePromotionsList(data.promotionsDetails.appliedCouponsCodes);
        $(".promo-codes-wrapper").empty().append(data.promotionsDetails.appliedCouponsHTML);
        $(".rewards-codes-wrapper").empty().append(data.loyaltyRewards.rewardsHtml);
        updateAccordionHeight();
    }

    if (data.approachingDiscounts) {
        updateApproachingDiscounts(data.approachingDiscounts);
    }

    if (data.totals && data.totals.orderLevelDiscountsHtml) {
        updateOrderLevelDiscounts(data);
    }

    if (data.totals && data.totals.shippingLevelDiscountsHtml) {
        updateShippingLevelDiscounts(data);
    }

    $(".savings-total").empty().append(data.totals.savings);
    $(".container-savings").toggleClass("d-none", !data.totals.hasSavings);
};

base.updateSummaryZipCode = function (data) {
    if (data && data.order.zipCode) {
        $(".js-new-zipcode").text(data.order.zipCode);
    }
};

base.updateEarnToPoints = function (data) {
    if (data && data.loyaltyRewards && data.loyaltyRewards.earnUpToPointsFormatted) {
        $(".js-earn-to-points").text(data.loyaltyRewards.earnUpToPointsFormatted);
    }
};

function updateAccordionHeight() {
    $(".collapsible-body").each(function () {
        let padding =  parseInt($(this).css("padding-top"), 10)*2;
        let formWrapper = $(this).find(".promo-code-form").outerHeight(true);
        let slotsWrapper = $(this).find(".slots-codes-wrapper").outerHeight(true);
        let promosWrapper = $(this).find(".promo-codes-wrapper").outerHeight(true);
        let summaryErrorsWrapper = $(this).find(".promo-summary-errors-wrapper").length > 0 ? $(this).find(".promo-summary-errors-wrapper").outerHeight(true) : 0;
        let titleHeight = $(this).siblings(".promotional-title").outerHeight(true);
        let rewardsWrapper = $(this).find(".rewards-codes-wrapper");
        let calcHeight = (rewardsWrapper.length > 0 ? rewardsWrapper.outerHeight(true) : promosWrapper + slotsWrapper + formWrapper + summaryErrorsWrapper) + titleHeight + padding;

        if ($(this).is(":visible")) {
            $(this).parent(".promotional-wrapper").css("min-height", calcHeight);
        }
    });
    return false;
}

function updatePromotionsList(promotionIds) {
    $(".code-wrapper").removeClass("d-none");
    promotionIds.forEach(function (promoID) {
        let coupon = $(".slots-codes-wrapper").find("[data-code="+promoID.toUpperCase()+"], [data-code="+promoID.toLowerCase()+"]");
        coupon.parents(".code-wrapper").addClass("d-none");
        $(".header-banner").find("[data-coupon-code="+promoID+"]").text($("[data-applied-text]").data("applied-text"));
    });
}

function updateApproachingDiscounts(approachingDiscounts) {
    var html = "";
    $(".approaching-discounts").empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            if (item.discountMsg) {
                html += "<div class=\"single-approaching-discount text-start\">"
                    + item.discountMsg + "</div>";
            }
        });
    }
    $(".approaching-discounts").append(html);
}
function updateOrderLevelDiscounts(data) {
    const $orderLevelContainer = $(".js-order-level-discounts");
    if ($orderLevelContainer.length > 0) {
        $orderLevelContainer.empty().append(data.totals.orderLevelDiscountsHtml);
    }
}

function updateShippingLevelDiscounts(data) {
    const $shippingLevelContainer = $(".js-shipping-level-discounts");
    if ($shippingLevelContainer.length > 0) {
        $shippingLevelContainer.empty().append(data.totals.shippingLevelDiscountsHtml);
    }
}

function updateSelectedPayment(data) {
    if (data && data.order && data.order.billing) {
        const selectedPayments = data.order.billing.selectedPaymentInstruments;
        if (selectedPayments && selectedPayments.length > 0) {
            const PAYMENT_METHODS = $("#paymentMethods").data("payments");
            const isPaypalSelected = selectedPayments.find(function (payment) {
                return payment.paymentMethod === PAYMENT_METHODS.PAYPAL;
            });
            if (isPaypalSelected) {
                $(".billing-payment-container .PayPal .js-payment-option").trigger("click");
            }
        }
    }
}

/**
 * Shows not applicable promotions for each item in the data object
 * @param {Object} data - the data object containing items and their not applicable promotions
 * @returns {void}
 */
function showNotApplicablePromo(data) {
    data.items.forEach(item => {
        let idClass = item.UUID;
        let notApplicableMsg = $(".uuid-" + idClass).find(".js-not-applicable-promotions");

        if (item.notApplicablePromotions && item.notApplicablePromotions.length !== 0 || (item.bundledProductLineItems && item.bundledProductLineItems.length > 0) && (item.bundledProductLineItems[0].notApplicablePromotions && item.bundledProductLineItems[0].notApplicablePromotions.length > 0)) {
            notApplicableMsg.removeClass("d-none").addClass("d-flex-column");
            notApplicableMsg.empty();
            item.notApplicablePromotions.forEach(function (notApplicablePromotion) {
                var $notApplicablePromotionMsg =$("<span></span>")
                    .addClass("text text-sm d-block medium text-color-e300 js-not-applicable-promotions-text")
                    .text(notApplicablePromotion);
                notApplicableMsg.append($notApplicablePromotionMsg);
            });
        } else {
            notApplicableMsg.removeClass("d-flex-column").addClass("d-none");
        }
    });

    if (data.promotionsDetails && data.promotionsDetails.summaryError) {
        $(".js-promo-summary-error")
            .empty()
            .append(data.promotionsDetails.summaryError)
            .addClass("pdd-16-top")
            .show();
    } else {
        $(".js-promo-summary-error")
            .empty()
            .removeClass("pdd-16-top")
            .hide();
    }

    updateAccordionHeight();
}

base.updateSelectedPayment = updateSelectedPayment;
base.updateAccordionHeight = updateAccordionHeight;
base.updatePromotionsList = updatePromotionsList;
base.updateApproachingDiscounts = updateApproachingDiscounts;
base.updateOrderLevelDiscounts = updateOrderLevelDiscounts;
base.updateShippingLevelDiscounts = updateShippingLevelDiscounts;
base.updateColoradoRetailFee = updateColoradoRetailFee;
base.showNotApplicablePromo = showNotApplicablePromo;

const exportsObj = {
    ...base
};

Object.keys(exportsObj).forEach(function (method) {
    exports[method] = exportsObj[method];
});
