"use strict";

function getRecaptchaToken(callback, actionParam) {
    var action = actionParam || "submit";
    var recaptchaKey = $("#recaptchaSiteKey").val();
    if (recaptchaKey) {
        let grecaptcha = window.grecaptcha;
        grecaptcha.ready(function () {
            grecaptcha.execute(recaptchaKey, {action: action}).then(function (token) {
                callback(token);
            });
        });
    } else {
        return callback();
    }
}

module.exports = {
    getRecaptchaToken: getRecaptchaToken
};
