/**
 * Update the URL with the given parameter
 * @param {string} url - The URL to update
 * @param {string} name - The name of the parameter to update
 * @param {string} value - The value of the parameter to update
 * @returns {string} The updated URL
 */
function updateURLWithParam(url, name, value) {
    if (url.indexOf(name + "=") !== -1) {
        var regexp = new RegExp(`(${name}=)(.*?)(?=&|$)`);
        return url.replace(regexp, `$1${value}`);
    }
    var separator = url.indexOf("?") !== -1 ? "&" : "?";
    return url + separator + name + "=" + encodeURIComponent(value);
}

/**
* Retrieves the store refinement number from the URL parameters
* @param {URLSearchParams} urlParams - The URL search parameters object
* @returns {string} The store refinement number extracted from the URL parameters
*/
function getStoreRefinementNumber(urlParams) {
    var refinementNumber;
    for (var [key, value] of urlParams.entries()) {
        if (value === "stores") {
            refinementNumber = key.replace("prefn", "");
            break;
        } else if (value === "stores2") {
            refinementNumber = key.replace("prefn", "");
            break;
        } else if (value === "stores3") {
            refinementNumber = key.replace("prefn", "");
            break;
        }
    }
    return refinementNumber;
}
module.exports = {
    updateURLWithParam,
    getStoreRefinementNumber
};
