"use strict";

/**
 * appends params to a url
 * @param {string} data - data returned from the server"s ajax call
 */

module.exports = {
    addToCart: function () {
        $("body").on("click", ".js-add-to-cart", function (e) {
            e.preventDefault();
            var $this = $(this);
            let url = $this.attr("href");
            let pid = $this.data("addToCartProductId");
            let quantity = $this.data("quantity");
            if ($this.parents(".modal-content").find(".js-quantity").length > 0) {
                quantity = $this.parents(".modal-content").find(".js-quantity").val();
            }
            let optionId = $this.closest(".product-detail").find(".product-option").attr("data-option-id");
            let optionVal = $this.closest(".product-detail").find(".options-select option:selected").attr("data-value-id");
            optionId = optionId || null;
            optionVal = optionVal || null;
            if (!url || !pid) {
                return;
            }

            var availableForInStorePickup = $this.data("availableForStorePickup");
            var availableForShipToHome    = $this.data("availableForShipToHome");

            if (availableForInStorePickup && !availableForShipToHome) {
                var selectedStoreID = $(".js-selected-store-id").val();
                url = url + "?storeId=" + selectedStoreID;
            }

            var selectedDeliveryOpt = availableForInStorePickup && !availableForShipToHome ? "ISPU" : "STH";

            var addToCartPayload = {
                pid: pid,
                optionId: optionId,
                optionVal: optionVal,
                quantity: quantity,
                selectedDeliveryOpt: selectedDeliveryOpt
            };

            if ($this.data("isProductSet") === true) {
                addToCartPayload.pidsObj = JSON.stringify($this.data("pidsObject"));
            }

            var $eGiftForm  = $this.siblings("form[name=js-egift-form-wishlist]");
            var $isDigitalGiftCard = $eGiftForm && $eGiftForm.find("[name=isDigitalGiftCard]");
            addToCartPayload.isDigitalGiftCard = false;
            if ($isDigitalGiftCard.length > 0 && $isDigitalGiftCard.val() === "true") {
                $eGiftForm.serializeArray().forEach(function (field) {
                    addToCartPayload[field.name] = field.value;
                });
                addToCartPayload.isDigitalGiftCard = true;
            }

            $.spinner().start();
            $.ajax({
                url: url,
                type: "post",
                dataType: "json",
                data: addToCartPayload,
                success: function (response) {
                    $(".minicart").trigger("count:update", response);
                    $.spinner().stop();
                    $(".quick-view-dialog .close").trigger("click");
                    $(".minicart-popover").addClass("add-to-cart-notification");
                    $(".minicart .popover-open").trigger("click");

                    if (addToCartPayload.pidsObj) {
                        $(".minicart .popover").addClass("set-notification");
                        window.pidsObj = JSON.parse(addToCartPayload.pidsObj);
                    }

                    if (response.error) {
                        window.dispatchEvent(new CustomEvent("addToCartFail", {detail: {data: response.message}}));
                    } else {
                        var recommendationContainer = $this.closest(".carousel-container");
                        var isPPC = $(".js-ppc-plp ").length > 0;

                        if (recommendationContainer.length) {
                            var jsCarouselTitle = isPPC ? $(recommendationContainer).find(".recommendations-title .questa") : $(recommendationContainer).find(".js-a-carousel-title");

                            if (jsCarouselTitle.length) {
                                response.aCart.products[0].addLocation = jsCarouselTitle[0].innerText;
                            }
                        } else if (isPPC && $this.closest(".js-ppc-plp").length > 0) {
                            response.aCart.products[0].addLocation = "ppc-plp";
                        }

                        window.dispatchEvent(new CustomEvent("addToCartSuccess", {
                            detail: {
                                ...response.aCart,
                            }
                        }));
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    }
};
