
"use strict";

/**
 * Handle body scroll
 * @param {Boolean} stopScroll
 */
let handleBodyScrolling = function (stopScroll) {
    if (stopScroll) {
        var offsetY = window.pageYOffset;
        $("body").css({"top": -offsetY + "px"}).addClass("no-scroll");
    } else {
        var scrollY = document.body.style.top;
        $("body").removeClass("no-scroll").removeAttr("style");
        window.scrollTo(0, parseInt((scrollY || "0"), 10) * -1);
    }
};


/**
 * Format a date object into a string
 * @param {Date} date - The date to format
 * @returns {string} - The formatted date
 */
let formatDate = function (date) {
    const monthNames = [
        "January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October",
        "November", "December"
    ];

    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();

    var formattedDateStr =  monthNames[monthIndex] + " " + day + ", " + year;

    return formattedDateStr;
};

function stripTags(html) {
    let doc = new DOMParser().parseFromString(html, "text/html");
    let text = doc.body.textContent;
    return text.replace(/(\r\n|\n|\r)/gm, "") || "";
}

module.exports = {
    handleBodyScrolling,
    formatDate,
    stripTags
};
