"use strict";

// const DEFAULT_COUNTRY_CODE = "US";
const breadPLCCData = document.getElementById("bread-plcc-data");
const isPLCCEnabled = checkPLCCStatus();
const customerAuthenticated = isCustomerLogged();
const loginTrigger = document.getElementsByClassName("js-login-drawer-open");
const PlccAnalytics = require("storefront/analytics/analyticIndex").PlccAnalytics;

function isCustomerLogged() {
    return isPLCCEnabled && breadPLCCData.dataset.customerAuthenticated && breadPLCCData.dataset.loyaltyId.length > 0;
}

// function getCustomerBillingAddress() {
//     const billingEl = document.getElementById("dwfrm_billing");

//     return {
//         givenName: billingEl.getElementsByClassName("billingFirstName")[0].value,
//         familyName: billingEl.getElementsByClassName("billingLastName")[0].value,
//         additionalName: "",
//         birthDate: "",
//         email: "", //document.getElementsByClassName("shippingEmailAddress")[0].value
//         phone: billingEl.getElementsByClassName("billing-phone")[0].value,
//         billingAddress: {
//             address1: billingEl.getElementsByClassName("billingAddressOne")[0].value,
//             address2: billingEl.getElementsByClassName("billingAddressTwo")[0].value,
//             country: DEFAULT_COUNTRY_CODE,
//             locality: billingEl.getElementsByClassName("billingAddressCity")[0].value,
//             region: billingEl.getElementsByClassName("billingState")[0].value,
//             postalCode: billingEl.getElementsByClassName("billingZipCode")[0].value
//         }
//     };
// }

function breadPlacementsSetup() {
    if (breadPLCCData) {
        let setupConfig = {
            loyaltyID: breadPLCCData.dataset.loyaltyId, //optional
            storeNumber: breadPLCCData.dataset.storeNumber, //9990
            env: breadPLCCData.dataset.env, // STAGE | PROD
        };

        window.BreadPayments.setup(setupConfig);
    }
}

// function breadRealtimeSetup() {
//     if (breadPLCCData) {
//         let setupConfig = {
//             loyaltyID: breadPLCCData.dataset.loyaltyId,
//             storeNumber: breadPLCCData.dataset.storeNumber,
//             env: breadPLCCData.dataset.env,
//             buyer: getCustomerBillingAddress()
//         };

//         window.BreadPayments.setup(setupConfig);
//     }
// }

function plccPlacementTrigger() {
    breadPlacementsSetup();

    let placementObjs = document.getElementsByClassName("js-plcc-placement");

    for (let i = 0; i < placementObjs.length; i++) {
        const placementInfo = placementObjs[i].dataset;
        let plccPlacementData = {
            order: {
                totalPrice: breadPLCCData.dataset.orderTotal
            }
        };

        plccPlacementData.financingType = placementInfo.financingType;
        plccPlacementData.locationType = placementInfo.locationType;
        plccPlacementData.placementId = placementInfo.placementId;
        plccPlacementData.domID = placementInfo.domId;

        window.BreadPayments.registerPlacements([plccPlacementData]);

        if (!customerAuthenticated) {
            placementObjs[i].addEventListener("click", function () {
                if (loginTrigger.length > 0) {
                    loginTrigger[0].click();
                }
            });
        }
    }
}

function checkPLCCStatus() {
    return window.BreadPayments && breadPLCCData ? true : false;
}

function initBreadEventHandlers() {
    if (isPLCCEnabled) {
        // To do - Check if this is still necessary or was only used for testing
        // window.BreadPayments.on("CARD:*", function (data) {
        //     console.log(this.event, data);
        // });

        window.BreadPayments.on("CARD:RECEIVE_APPLICATION_RESULT", (cardResult) => {
            if (cardResult.result === "APPROVED") {
                createCustomerOffer();

                if (cardResult.callId) {
                    saveTokenInSession(cardResult.callId);
                }
            }

            window.dispatchEvent(new CustomEvent("plccFormSubmitEvent", {
                detail: {
                    customer: {
                        plccApplicationStatus: cardResult.result === "APPROVED" ? "approved" : "deferred"
                    }
                }
            }));
        });

        let pageIndex = 0;
        window.BreadPayments.on("CARD:SHOW_OVERLAY_CONTENT", () => {
            pageIndex++;

            // apply now has been clicked
            if (pageIndex === 2) {
                window.dispatchEvent(new CustomEvent("plccFormStartEvent", {}));
            }
        });

        window.BreadPayments.on("CARD:CLOSE_OVERLAY", () => { pageIndex = 0; });
    }
}

function createCustomerOffer() {
    $.ajax({ url: breadPLCCData.dataset.sendCustomerOfferUrl, method: "post" });
}

function saveTokenInSession(token) {
    $.ajax({
        url: breadPLCCData.dataset.saveTokenInSessionUrl,
        method: "post",
        data: {
            token: token,
            device: getDeviceType(),
        }
    });
}

function getDeviceType() {
    // PLCC requires to pass mobile or anything else, as such there are just 2 breakpoints defined
    const breakpoints = {
        mobile: "(max-width: 767px)",
        desktop: "(min-width: 768px)"
    };

    // Check the media queries against the window width
    for (const device in breakpoints) {
        if (window.matchMedia(breakpoints[device]).matches) {
            return device;
        }
    }

    // Default to desktop if no breakpoint matches
    return "desktop";
}

function initPlccTrackingEvent() {
    const plccAnalytics = new PlccAnalytics();
    plccAnalytics.collect();
}

module.exports = {
    init: function () {
        if (isPLCCEnabled) {
            plccPlacementTrigger();
            initBreadEventHandlers();
            initPlccTrackingEvent();

            // TODO: Left out as it is currently in business review post mvp
            // $("body").on("checkout:triggerBreadRTPS", function (_event, _data) {
            //     breadRealtimeSetup();

            //     window.BreadPayments.submitRtps({
            //         financingType: "card",
            //         locationType: "checkout", // homepage, landing, search, product, category, banner, checkout cart
            //     });
            // });

            $("body").on("plcc:triggerBreadBPRS", function (_event, _data) {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                let hasAccept = urlParams.has("accept");
                let hasPreScreenId = urlParams.has("prescreenId");
                let rtpsSetup = {
                    financingType: "card",
                    locationType: "landing",
                    customerAcceptedOffer: "true"
                };

                if (hasPreScreenId || hasAccept) {
                    if (customerAuthenticated) {
                        window.BreadPayments.submitRtps(rtpsSetup);
                    } else {
                        setTimeout(() => {
                            $(".js-login-drawer-open")[0].click();
                        }, 300);
                    }
                }
            });
        }
    }
};
