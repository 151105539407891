"use strict";

var cart = require("../cart/cart");
var parsePromoHtml = require("./parsePromoHtml");
var updateMiniCart = true;
var isCartPage = $(".cart-header").length > 0;
const CartAnalytics = require("../analytics/analyticIndex.js").CartAnalytics;

function updateCartTotals(data) {
    $(".number-of-items").empty().append(data.resources.numberOfItems);
    $(".shipping-cost").empty().append(data.totals.totalShippingCost);
    $(".tax-total").empty().append(data.totals.totalTax);
    $(".grand-total").empty().append(data.totals.grandTotal);
    $(".sub-total").empty().append(data.totals.subTotal);
    $(".surcharge-cost").empty().append(data.totals.totalSurchargeCost.formatted);
    $(".savings-total").empty().append(data.totals.savings);
    $(".minicart-quantity").empty().append(data.numItems);
    $(".container-savings").toggleClass("d-none", !data.totals.hasSavings);
    $(".minicart-link").attr({
        "aria-label": data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    var parsedPromoHtml = parsePromoHtml(data.renderedTemplate);
    $(".cart-promo-details").empty().html(parsedPromoHtml.body);

    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $(".order-discount").removeClass("hide-order-discount");
        $(".order-discount-total").empty()
            .append("- " + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $(".order-discount").addClass("hide-order-discount");
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $(".shipping-discount").removeClass("hide-shipping-discount");
        $(".shipping-discount-total").empty().append("- " +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $(".shipping-discount").addClass("hide-shipping-discount");
    }

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $(".coupons-and-promos").empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $(".item-" + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $(".item-" + item.UUID).empty();
        }
        $(".uuid-" + item.UUID + " .unit-price").empty().append(item.renderedPrice);
        $(".line-item-price-" + item.UUID + " .unit-price").empty().append(item.renderedPrice);
        $(".item-total-" + item.UUID).empty().append(item.priceTotal.renderedPrice);
        $(".item-price-minicart-" + item.UUID).empty().append(item.priceTotal.price);
    });

    if (data.totals.promotions) {
        $(".total-promotions").removeClass("d-none");
        $(".total-promotions").empty();
        data.totals.promotions.forEach(function (promotion) {
            var $htmlPromotion = `<div class="d-flex align-items-center justify-content-between col-12">
            <span class="text text-lg medium text-color-t300">
                ${promotion.promotionName}
            </span>
            <span class="text text-lg medium text-color-t300">
                ${promotion.totalDiscount}
            </span>
            </div>`;
            $(".total-promotions").append($htmlPromotion);
        });
    } else {
        $(".total-promotions").addClass("d-none");
    }
}

function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf("?") !== -1 ? "&" : "?") + Object.keys(params).map(function (key) {
        return key + "=" + encodeURIComponent(params[key]);
    }).join("&");

    return newUrl;
}

function updateApproachingDiscounts(approachingDiscounts) {
    var html = "";
    $(".approaching-discounts").empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += "<div class='single-approaching-discount text-center'>"
                + item.discountMsg + "</div>";
        });
    }
    $(".approaching-discounts").append(html);
}

function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = "<div class='alert alert-danger alert-dismissible valid-cart-error " +
                "fade show' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'>" +
                "<span aria-hidden='true'>&times;</span>" +
                "</button>" + data.valid.message + "</div>";

            $(".cart-error").append(errorHtml);
        } else {
            $(".cart").empty().append("<div class='row'> " +
                "<div class='col-12 text-center'> " +
                "<h1>" + data.resources.emptyCartMsg + "</h1> " +
                "</div> " +
                "</div>"
            );
            $(".number-of-items").empty().append(data.resources.numberOfItems);
            $(".minicart-quantity").empty().append(data.numItems);
            $(".minicart-link").attr({
                "aria-label": data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $(".minicart .popover").empty();
            $(".minicart .popover").removeClass("show");
        }
    }
}

function createErrorNotification(message) {
    var errorHtml = "<div class='alert alert-danger alert-dismissible valid-cart-error " +
        "fade show' role='alert'>" +
        "<button type='button' class='close' data-dismiss='alert' aria-label='Close'>" +
        "<span aria-hidden='true'>&times;</span>" +
        "</button>" + message + "</div>";

    $(".cart-error").append(errorHtml);
}

module.exports = function () {
    if (!isCartPage) {
        cart();
    }

    $(".minicart").on("count:update", function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $(".minicart .minicart-quantity").text(count.quantityTotal);
            $(".minicart .minicart-link").attr({
                "aria-label": count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $(".minicart .popover-open").on("click", function (e) {
        if ($(window).innerWidth() < 991 && $(".popover.add-to-cart-notification").length === 0 && $(".popover.promocode-notification").length === 0) {
            window.location.href = $(".minicart-link").attr("href");
        } else {
            e.preventDefault();

            if ($(".search:visible").length === 0) {
                return;
            }
            var url = $(".minicart").data("action-url");
            var count = parseInt($(".minicart .minicart-quantity").text(), 10);
            var promoApplied = $(".popover.promocode-notification").length > 0;
            var link = $(".minicart-link").attr("href");

            if ((count !== 0 || promoApplied) && $(".minicart .popover .d-none").length === 0) {
                if (!updateMiniCart) {
                    window.dispatchEvent(new CustomEvent("minicartShow"), {});
                    return;
                }

                $.ajax({
                    url: url,
                    headers: {
                        Accept : "*/*,application/json"
                    },
                    success: function (data) {
                        $(".minicart .popover").empty();
                        $(".minicart .popover").append(data);
                        updateMiniCart = false;

                        if (window.pidsObj) {
                            for (var i = 0; i < window.pidsObj.length; i++) {
                                var pid = window.pidsObj[i].pid;
                                $(document).find(("[data-sku='" + pid + "']")).show();
                            }
                            window.pidsObj = false;
                        }
                        window.dispatchEvent(new CustomEvent("minicartShow"), {});
                        $(".minicart-wrapper").addClass("active");

                        if ($(".minicart-wrapper").hasClass("active")) {
                            $("html, body").addClass("no-scroll-top");
                        }

                        
                        
                        var $aCartError = $("#js-a-minicart-error");
                        if ($aCartError) {
                            const cartAnalytics = new CartAnalytics();
                            var aCartErrorType = $aCartError.data("type");
                            if (aCartErrorType) {
                                cartAnalytics.cartError({
                                    detail: aCartErrorType
                                });
                            }
                        }
                    }
                });
            } else {
                window.location.href = link;
            }
        }
    });

    $("body").on("click", ".delete-coupon-minicart", function (e) {
        e.preventDefault();

        var url = $(this).data("action");
        var uuid = $(this).data("uuid");
        var couponCode = $(this).data("code");
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $("body > .modal-backdrop").remove();

        $.spinner().start();
        $("body").trigger("promotion:beforeUpdate");
        $.ajax({
            url: url,
            type: "get",
            headers: {
                Accept: "*/*,application/json"
            },
            dataType: "json",
            success: function (data) {
                $(".coupon-uuid-" + uuid).remove();
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                $.spinner().stop();
                $("body").trigger("promotion:success", data);
                $(".js-minicart-close").trigger("click");
            },
            error: function (err) {
                $("body").trigger("promotion:error", err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $("body").on("click", ".js-minicart-close", function () {
        $(".minicart-wrapper").removeClass("active");
        $(".minicart-popover").removeClass("add-to-cart-notification");
        $(".minicart-popover").removeClass("promocode-notification");
        $(".minicart-popover").removeClass("set-notification");
        $("html, body").removeClass("no-scroll-top");
        $(".minicart").removeClass("active");
        $(".minicart .minicart-popover").addClass("d-none");
        $(".minicart .popover").empty();
        updateMiniCart = true;
    });

    $("body").on("click", ".js-promocode-detail", function () {
        $(".minicart-popover").addClass("promocode-notification");
        $(".minicart .popover-open").trigger("click");
    });

    $("body").on("change", ".minicart .quantity", function () {
        if ($(this).parents(".bonus-product-line-item").length && isCartPage) {
            location.reload();
        }
    });

    $("body").on("product:afterAddToCart", function (event, data) {
        updateMiniCart = true;

        if (!data.error) {
            //add to cart notification for mini cart
            $(".minicart-popover").addClass("add-to-cart-notification");
            $(".minicart .popover-open").trigger("click");
        }
    });

    $("body").on("cart:update", function () {
        updateMiniCart = true;
    });

    $("body").on("count:update", function () {
        updateMiniCart = true;
    });
};
