"use strict";

var clearSelection = function (element) {
    $(element).closest(".dropdown-item.show").removeClass("show");
};

module.exports = function () {
    var isDesktop = function (element) {
        return $(element).parents(".menu-toggleable-left").css("position") !== "fixed";
    };

    $(".js-subnav-toggle")
        .on("click", function (e) {
            if (!isDesktop(this)) {
                // copy subnav title to subcategory span
                var categoryTitle  = $(this).text();
                $(".subnav .js-selected_category").text(categoryTitle);
                $(".subnav .close-menu").removeClass("d-none");
                $(this).parent().addClass("show");
                $(this).attr("aria-expanded", "true");
                e.preventDefault();
            }
        });

    $(".navbar-nav").on("click", ".back-subnav", function (e) {
        e.preventDefault();
        clearSelection(this);
    });
};
