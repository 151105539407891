"use strict";

var scrollAnimate = require("base/components/scrollAnimate");

function getProductSchema() {
    try {
        const $productSchema = $("#js-product-schema");
        if ($productSchema.length > 0) {
            return JSON.parse($productSchema.val());
        }
        throw new Error("");
    } catch (error) {
        return {};
    }
}

module.exports = {
    init: function () {
        var prDataSelectors = document.querySelectorAll("div.power-reviews .power-reviews-data");
        var renderArray = [];
        prDataSelectors.forEach((prDataSelector) => {
            const prApiKey = prDataSelector.dataset.apiKey;

            if (prApiKey) {
                const prLocale = prDataSelector.dataset.locale;
                const prMerchantGroupId = prDataSelector.dataset.merchantGroupId;
                const prMerchantId = prDataSelector.dataset.merchantId;
                const prWriteWrapperURL = prDataSelector.dataset.writeWrapperUrl;
                const prFeedlessData = prDataSelector.dataset.feedlessData;
                const productOrMasterId = prDataSelector.dataset.productOrMasterId;
                var components = {};

                var reviewSnippetSelector =  "pr-reviewsnippet-" + productOrMasterId;
                if (document.querySelector("#"+reviewSnippetSelector)) {
                    components.ReviewSnippet = reviewSnippetSelector;
                }

                var reviewDisplaySelector = "pr-reviewdisplay-" + productOrMasterId;
                if (document.querySelector("#"+reviewDisplaySelector)) {
                    components.ReviewDisplay = reviewDisplaySelector;
                }

                var questionSnippetSelector = "pr-questionsnippet-" + productOrMasterId;
                if (document.querySelector("#"+questionSnippetSelector)) {
                    components.QuestionSnippet = questionSnippetSelector;
                }

                var questionDisplaySelector = "pr-questiondisplay-" + productOrMasterId;
                if (document.querySelector("#"+questionDisplaySelector)) {
                    components.QuestionDisplay = questionDisplaySelector;
                }

                renderArray.push({ //eslint-disable-line no-undef
                    api_key: prApiKey,
                    locale: prLocale,
                    merchant_group_id: prMerchantGroupId,
                    merchant_id: prMerchantId,
                    page_id: productOrMasterId,
                    review_wrapper_url: prWriteWrapperURL,
                    product: JSON.parse(prFeedlessData),
                    components: components,
                    subject: getProductSchema(),
                    on_render: function (config, data) {
                        if (config.component == "ReviewSnippet") {
                            var productReviewSnippetSelector = "div#pr-reviewsnippet-" + productOrMasterId + "[data-pr-component=ReviewSnippet]";
                            var $revSnippet = $(document).find(productReviewSnippetSelector);
                            $revSnippet.find(".pr-snippet-write-review-link").text($revSnippet.attr("data-write-text"));
                            $revSnippet.find(".pr-snippet-review-count").text(data.review_count);
                        }

                        if (config.component == "ReviewDisplay") {
                            var $revMain = $(document).find("[data-pr-component=ReviewDisplay]");

                            $revMain.find(".pr-snippet-write-review-link").text($revMain.attr("data-write-text"));
                            $revMain.addClass("loaded");
                            $revMain.toggleClass("no-reviews", data.review_count == undefined);
                        }

                        if (config.component == "QuestionSnippet") {
                            var $qaSnippet = $(document).find("[data-pr-component=QuestionSnippet]");
                            $qaSnippet.addClass("loaded");
                        }

                        if (config.component == "QuestionDisplay") {
                            var $qaMain = $(document).find("[data-pr-component=QuestionDisplay]");
                            $qaMain.find(".pr-qa-display-searchbar-input").attr("placeholder", $qaMain.attr("data-qa-text"));
                            $qaMain.find(".pr-qa-display-show-more-answers .pr-qa-display-text").text($qaMain.attr("data-see-all-qa-text"));
                        }
                    }
                });
            }
        });

        window.pwr = window.pwr || function () {
            (pwr.q = pwr.q || []).push(arguments); // eslint-disable-line no-undef
        };
        pwr("render", renderArray);// eslint-disable-line no-undef

        $(document).on("click", ".pr-snippet-rating-decimal", function () {
            if ($(".pr-review-snapshot").length > 0) {
                scrollAnimate($(".pr-review-snapshot"));
            }
        });
    }
};
